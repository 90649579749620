.minegocio-wrapper {
  position: relative;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.minegocio-content-wrp {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  min-width: 320px;
  width: 100%;
  padding: 0 5%;
}

.minegocio-title {
  color: #3b3b3b;
  font-weight: 500;
  font-size: 2rem;
  text-align: left;
}
.minegocio-subtitle {
  text-align: left;
  font-size: 0.9rem;
}

.grid-packages {
  margin-top: 50px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.item {
  display: flex;
  justify-content: flex-start;
}

.create-package-button-mis-negocios {
  width: 300px;
  height: 200px;
  position: relative;
  cursor: pointer;
}
  .create-package-button-img-mis-negocios{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .create-package-button-p-mis-negocios{
    position: absolute;
    top:60%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #3b3b3b;
    font-size: 0.7rem;
    text-align: center;
  }
  .create-package-button-icon-mis-negocios{
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #3b3b3b;
    font-size: 2.5rem;
    text-align: center;
  }
.create-package-button-img-mis-negocios:hover{
  transform: scale(0.97);
}
@media screen and (max-width: 1250px) {
  .grid-packages {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 850px) {
  .grid-packages {
    grid-template-columns: repeat(1, 1fr);
  }
  .item {
    display: flex;
    justify-content: center;
  }
}

/* Celular */
@media screen and (max-width: 430px) {
  .minegocio-wrapper {
    align-items: flex-start;
  }
  .minegocio-title {
    font-size: 1.5rem;
    text-align: center;
  }
  .minegocio-subtitle {
    text-align: center;
  }
}
