.wrap-types-of-events{
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-family: sans-serif;
}

.types-of-events-box{
    border-radius: 10px;
    margin: 1rem;
    border: solid transparent 3px;
    -webkit-box-shadow: 0 0 0 1px #f6f3f3;
    box-shadow: 0 0 0 1px #f6f3f3;
    width: 200px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: center bottom;
    padding: 0.6rem 0.6rem 0rem 0.6rem;
    /*overflow: hidden;*/
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    -webkit-filter: grayscale(0.3); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(0.3); /* Microsoft Edge and Firefox 35+ */
}
.types-of-events-box:hover{
    -webkit-box-shadow: 0 0 7px 2px rgba(0,0,0,0.13);
    box-shadow: 0 0 7px 2px rgba(0,0,0,0.13);
}
.types-of-events-box.active{
    overflow: hidden;
    margin: 1rem;
    border: solid #8C50FF 3px;
    -webkit-filter: grayscale(0);
    filter: none;
}

/*mobile*/
@media screen and (max-width: 960px){

}