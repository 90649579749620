.privacy-options-wrp {
  margin: 2rem 8rem;
  padding: 2rem 8rem;
}

.privacy-options-main-title-div {
  display: flex;
}
.privacy-options-main-title-div p {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.privacy-options-main-content-div p {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 1rem;
}

/* .privacy-options-btn {
  border-radius: 10px;
  background-color: black;
  color: white;
  font-size: 15px;
  padding: 8px;
  border: none;
  opacity: 0.9;
  margin: 0.5rem;
} */

/* Hover effects */
/* .privacy-options-btn:hover {
  opacity: 1;
} */

/* CellPhone */
@media screen and (max-width: 960px) {
  .privacy-options-wrp {
    margin: 1rem 2rem;
    padding: 1rem 2rem;
  }
}

