.wrp-create-packages-afil {
  width: 100%;
  /*min-height: 500px;*/
}

.wrp-packages-afil-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: center;
  margin-top: 2rem;
}
.create-package-button {
  min-height: 200px;
  width: 300px;
  cursor: pointer;
  opacity: 0.7;
  position: relative;
}
.create-package-button:hover .create-package-button-img {
  transform: scale(0.97);
}
.create-package-button-img {
  width: 100%;
  height: 100%;
}
.create-package-button-p {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3b3b3b;
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500;
}
.create-package-button-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3b3b3b;
  font-size: 2.5rem;
  text-align: center;
}
.create-package-instructions {
  border-radius: 15px;
  background-color: #8c50ff;
  color: #ececec;
  width: 300px;
  height: 80px;
  display: table;
  margin-left: 1rem;
  -webkit-box-shadow: 5px 5px 11px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 5px 5px 11px -3px rgba(0, 0, 0, 0.35);
  pointer-events: none;
  animation: instruction-packages 2s infinite ease-in-out;
  align-self: center;
}
.create-package-instructions p {
  font-size: 0.9rem;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.create-package-warning-msg {
  margin: 1rem 0 0 0;
  justify-content: center;
  color: var(--planodi-purple);
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 0.8rem;
}

/*Modal    */
.create-package-section-div {
  width: 100%;
  padding: 0 0.5rem;
  margin-top: 1rem;
}
.create-package-section-div-p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #3b3b3b;
  margin: 0.5rem 0 0 0;
}
.create-package-section-price-input {
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.create-packages-click-checkboxes {
  display: flex;
  flex-direction: column;
}
.create-packages-click-checkboxes-label {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color: #3b3b3b;
  font-weight: 300;
  font-size: 0.85rem;
  text-align: center;
}

.img-buttons-packages-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  flex-direction: column;
}
.img-button-principal {
  width: 300px;
}
.modal-create-package-wrp-scroll::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background-color: #eeefef;
}
.modal-create-package-wrp-scroll::-webkit-scrollbar-thumb {
  background-color: #989898;
  border-radius: 99px;
}
.package-mod-prin-img-success {
  border: solid #d7d7d7 1px;
  border-radius: 10px;
  width: 96%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  max-height: 250px;
  overflow: scroll;
  padding: 10px;
  align-items: flex-start;
}
.package-mod-prin-img-success::-webkit-scrollbar {
  width: 8px;
  height: 0;
  background-color: #d7d7d7;
  border-radius: 0 99px 99px 0;
}
.package-mod-prin-img-success::-webkit-scrollbar-thumb {
  border-radius: 0 99px 99px 0;
  background-color: #737373;
}
.afil-div-preview-pckg {
  position: relative;
  cursor: pointer;
}
.afil-div-preview-pckg.active {
  border: solid #8c50ff 4px;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.18);
}
.afil-img-preview-pckg {
  width: 100%;
}
.principal-btn-upload {
  border-radius: 15px;
  width: 100%;
  color: #3b3b3b;
  padding: 1rem 1.5rem;
  text-align: justify;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: linear-gradient(to left, rgb(255, 255, 255) 50%, rgb(248, 247, 247) 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;
}
.principal-btn-upload:hover {
  /*background-color: #ffbd15;*/
  background-position: left;
}

.packages-example-box {
  width: 100%;
  border: solid #eeeeee 1px;
  border-radius: 15px;
  background-color: #fcfbfb;
  margin-top: 3rem;
  padding: 1rem 0 1rem 2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  position: relative;
  cursor: pointer;
  transition: height 0.5s;
}
.packages-example-box-button-hidde {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  height: 30px;
  display: table;
  padding: 0.5rem;
  border: solid #eeeeee 1px;
}
.packages-example-box-button-hidde:hover {
  background-color: #f8f8f8;
}
.packages-example-box-button-hidde-p {
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  color: #3b3b3b;
}
.packages-example-box-p {
  font-weight: 500;
  font-size: 1.1rem;
}
.packages-example-box-p2 {
  font-weight: 400;
  font-size: 0.9rem;
}
.packages-example-box-flex {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 10px;
}
.packages-example-box-flex-disappear {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}
.packages-example-box-flex::-webkit-scrollbar {
  height: 5px;
  background-color: #e5e7e9;
}
.packages-example-box-flex::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
}
.packages-example-box-flex-disappear:before {
  position: absolute;
  right: 0;
  height: 100%;
  width: 3rem;
  content: '';
  background: linear-gradient(to left, rgb(248, 248, 248) 8%, rgba(255, 255, 255, 0) 92%);
  z-index: 999;
  pointer-events: none; /* so the text is still selectable */
}
.packages-ex-box {
  margin: 0 10px;
}

/*EDITAR Paquetes*/
.packages-edit-bttns-wrp {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
.packages-edit-delete-button {
  position: absolute;
  top: 0.7rem;
  left: 2rem;
  background-color: #d63737;
  cursor: pointer;
  border-radius: 10px;
  height: 30px;
  display: table;
  padding: 0.5rem 1rem;
}
.packages-edit-delete-button:hover {
  background-color: #a93333;
}
.packages-edit-delete-button-p {
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  color: white;
}

.packages-delete-confirmation-wrp {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.packages-delete-confirmation-step4 {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .create-package-instructions {
    margin-left: 0;
    animation: instruction-packages-mobile 2s infinite ease-in-out;
  }
}

@media screen and (max-width: 960px) {
  .packages-delete-confirmation-wrp {
    height: 100%;
  }
}

@keyframes instruction-packages {
  50% {
    transform: translateX(10%);
  }
}

@keyframes instruction-packages-mobile {
  50% {
    transform: translateY(5%);
  }
}
