.wrp-all-home-page {
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.wrp-all-home-page::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.wrp-home-page {
  width: 100%;
  max-width: 1400px;
  min-width: 320px !important;
}
.fondo-gradiente {
  background: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );
}
.browser-blur-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 997;
  background: rgba(255, 255, 255, 0.85);
  /*backdrop-filter: blur( 5px );*/
  /*-webkit-backdrop-filter: blur( 5px );*/
  display: inline-block;
}
.home-page-ver-mas {
  text-decoration: none;
  color: #3b3b3b;
  border-radius: 99px;
  padding: 10px 35px;
  font-size: 1.1rem;
  -webkit-box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.6);
  margin: 15px 0 2rem 0;
}
.home-page-ver-mas:hover {
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .wrp-all-home-page {
    justify-content: left;
  }
  .home-page-ver-mas {
    font-size: 16px;
    margin: 0 0 1.5rem 0;
  }
}

