.buscador-planodi-wrp {
  background-color: white;
  max-width: 550px;
  width: 100%;
  border-radius: 8px;
  z-index: 999;
  display: flex;
  border: solid 2px rgb(228, 228, 228);
  gap: 10px;
  padding: 5px;
  position: relative;
  z-index: 1000 !important;
  min-width: 500px;
}
.buscador-planodi-label {
  position: absolute;
  margin: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.buscador-planodi-content {
  font-family: Roboto, sans-serif;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: #3b3b3b;
  transition: 0.2s;
  white-space: nowrap;
}

.buscador-planodi-input-1,
.buscador-planodi-input-2 {
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 3.6rem;
  color: #3b3b3b;
  padding-top: 1rem;
  border: none;
  outline: none;
  padding-left: 1rem;
  transition: 0.2s;
  background-color: transparent;
  padding-right: 18%;
}
.buscador-planodi-input-2 {
  border-left: solid 2px rgb(239, 239, 239);
  width: 150px;
}

.buscador-planodi-input-1:focus + .buscador-planodi-label .buscador-planodi-content,
.buscador-planodi-input-1:not(:placeholder-shown)
  + .buscador-planodi-label
  .buscador-planodi-content {
  transform: translateY(-130%);
  font-size: 13px;
  color: #888784;
}
.buscador-planodi-input-2:focus + .buscador-planodi-label .buscador-planodi-content,
.buscador-planodi-input-2:not(:placeholder-shown)
  + .buscador-planodi-label
  .buscador-planodi-content {
  transform: translateY(-130%);
  font-size: 13px;
  color: #888784;
}

.buscador-planodi-boton-go {
  background: var(--planodi-gray);
  color: white;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.buscador-planodi-boton-go:focus {
  outline: none;
}
.buscador-planodi-boton-go:hover {
  opacity: 90%;
  color: white;
}

.buscador-planodi-boton-go-mobile {
  background: var(--planodi-gray) !important;
  color: white !important;
  border-radius: 5px !important;
  height: 3.2rem !important;
  z-index: 997 !important;
  position: relative !important;
}

.buscador-planodi-box-location-wrp,
.buscador-planodi-box-quebuscas-wrp {
  position: absolute;
  top: 2.7rem;
  left: 1%;
  width: 98%;
  background-color: white;
  z-index: 998 !important;
  border-radius: 0 0 5px 5px;
  display: flex;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  font-family: sans-serif;
  padding: 32px 0 1rem 0;
}

.buscador-planodi-box-loc-div-2,
.buscador-planodi-box-loc-div-2 {
  margin: 0;
  padding: 0;
  cursor: pointer;
  margin-bottom: 10px;
}
.buscador-planodi-box-loc-div,
.buscador-planodi-box-quebuscas-div {
  display: flex;
  padding: 1.2rem;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-between;
  /*background-color: rgba(188, 221, 250, 0.09);*/
  background-color: white;
  height: 200px;
  font-size: 0.9rem;
}
.buscador-planodi-box-loc-div::-webkit-scrollbar,
.buscador-planodi-box-loc-div-mun::-webkit-scrollbar,
.buscador-planodi-box-quebuscas-div::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}
.buscador-planodi-box-loc-div::-webkit-scrollbar-thumb,
.buscador-planodi-box-loc-div-mun::-webkit-scrollbar-thumb,
.buscador-planodi-box-quebuscas-div::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: #7c7c7c;
}
.buscador-planodi-box-quebuscas-div-p {
  color: #3b3b3b;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 1.1rem !important;
}
.buscador-planodi-box-quebuscas-div-p.active {
  background-color: #3b3b3b;
  color: white;
}
.buscador-planodi-horizontal-overflow-blur-white {
  display: block;
  position: absolute;
  bottom: 0.4rem;
  width: 97%;
  height: 15px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 58%, rgba(255, 255, 255, 0) 86%);
}
.buscador-planodi-icon-div {
  position: absolute;
  right: 0;
  padding-right: 4%;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 960px) {
  .buscador-planodi-wrp {
    flex-direction: column;
    padding: 0px;
    border: none;
    gap: 8px;
    min-width: 200px;
  }
  .buscador-planodi-input-1,
  .buscador-planodi-input-2 {
    height: 3.6rem;
    border: solid 2px rgb(239, 239, 239);
    border-radius: 5px;
    width: 100%;
    padding-right: 10%;
  }

  .buscador-planodi-box-location-wrp,
  .buscador-planodi-box-quebuscas-wrp {
    padding-top: 0;
    top: 3.4rem;
    z-index: 1001 !important;
    left: 0;
    width: 100%;
  }
  .buscador-planodi-box-location-wrp {
    top: 7.5rem;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .buscador-planodi-box-loc-div-p:hover {
    background-color: rgba(59, 59, 59, 0.7);
    color: white;
  }
  .buscador-planodi-box-loc-div-p:hover.active:hover {
    background-color: rgba(59, 59, 59, 1);
  }
  .buscador-planodi-box-quebuscas-div-p:hover {
    background-color: rgba(59, 59, 59, 0.7);
    color: white;
  }
  .buscador-planodi-box-quebuscas-div-p:hover.active:hover {
    background-color: rgba(59, 59, 59, 0.7);
  }
}

