.rich-text-editor-styling {
  padding: 12px 15px;
  border: solid #5979a2 1px !important;
  border-radius: 10px;
  max-height: 500px;
  overflow: scroll;
}
.rich-text-editor-styling.md {
  min-height: 200px;
}
.rich-text-editor-styling.lg {
  min-height: 300px;
}
.rich-text-editor-text-size {
  font-size: 0.9rem;
}
.rich-text-editor-toolbar {
  background-color: #edf2fa;
  border-radius: 99px;
  padding: 7px 16px;
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.rich-text-editor-button {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  border: none !important;
  border-radius: 5px;
  padding: 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rich-text-editor-button:hover {
  background-color: #d2e1f5;
}
.rich-text-editor-button.active {
  background-color: #d2e1f5;
}

.rich-text-editor-styling-preview {
  padding: 0;
  border: none !important;
}
.rich-text-editor-title1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.rich-text-editor-title2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Celular */
@media screen and (max-width: 960px) {
  .rich-text-editor-title1 {
    font-size: 1.3rem;
    margin-bottom: 0.9rem;
  }
  .rich-text-editor-title2 {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  .rich-text-editor-toolbar {
    border-radius: 10px;
  }
}

