:root {
  --planodi-purple: #8c50ff;
  --planodi-blue: #0d3b66;
  --planodi-gray: #3b3b3b;
  --planodi-gold: #ffbc1d;
  --planodi-success: #00c851;
  --planodi-error: #ff4444;
  --planodi-warning: #ffbb33;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}
blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #747474;
  font-style: italic;
}
.debug {
  border: solid red 1px;
}

.buscador {
  width: 100%;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  align-items: center;
}

.selectInput {
  width: 10rem;
  border: 5px solid transparent;
  outline-width: 0;
}

.button-container {
  position: relative;
  height: 100%;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.companyInfo span {
  color: var(--planodi-blue);
}

.companyInfo ol li,
.companyInfo ul li {
  margin-left: 20px;
  list-style-type: initial;
}

.companyInfo ul li.list-disc {
  list-style-type: disc;
}

.companyInfo ul li.list-decimal {
  list-style-type: decimal;
}

.companyInfo ul li.list-lowerAlpha {
  list-style-type: lower-alpha;
}

.companyInfo ul li.list-lowerRoman {
  list-style-type: lower-roman;
}
.button-clean {
  border: none;
  background: none;
  outline: none;
}

.a-clean {
  text-decoration: none !important;
}
.a-clean:hover {
  text-decoration: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.bold {
  font-weight: 500;
}

.text-no-wrap-3-dots-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color-planodi-balck {
  color: var(--planodi-gray);
}

.text-center {
  text-align: center;
}

