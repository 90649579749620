.navbar-admin-all {
  overflow-x: scroll;
}
.navbar-admin-all::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.navbar-admin {
  display: flex;
  align-content: center;
  padding: 8px 0 8px 20px;
  background: #262626;
  color: white;
}
.admin-page-title {
  min-width: 320px;
  color: #858585;
  font-size: 1rem;
  max-width: 2000px;
  padding: 0 5%;
  margin: 2rem auto 0 auto;
}
.drawer-admin {
  width: 250px;
  background: #262626;
  color: white;
  height: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-drwer-logo {
  width: 120px;
  margin-bottom: 20px;
}
.admin-page-link {
  width: 100%;
  gap: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 3px 15px 10px;
}
.admin-page-link p {
  margin: 0;
}
.admin-page-link-icon {
  font-size: 1.4rem;
}

.admin-wrapper {
  position: relative;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}
.admin-content-wrp {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  min-width: 320px;
  width: 100%;
  padding: 0 5%;
}

.admin-title {
  color: #3b3b3b;
  font-weight: 500;
  font-size: 2rem;
  text-align: left;
  margin-top: 1.5rem;
}
.admin-subtitle {
  text-align: left;
  font-size: 0.9rem;
}
.admin-home-plot {
  margin: 3rem 0;
  display: flex;
  gap: 1rem;
}
.admin-home-plot-div {
  width: 50%;
}
.admin-home-totales-title {
  width: 100%;
  font-weight: 300;
  color: #3b3b3b;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.3rem;
}
.admin-home-totales {
  display: flex;
}
.admin-home-totales-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.admin-home-totales-num {
  font-size: 3.3rem;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
}
.admin-home-totales-desc {
  font-size: 1rem;
  color: #3b3b3b;
  margin: 0;
}

.table-wrapper-admin {
  width: 100%;
  margin-top: 2rem;
}

.table-admin {
  width: 100%;
  margin-top: 1rem;
}

.table-admin th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #8c50ff;
  color: white;
}

.table-admin td,
th {
  border: 1px solid #ddd;
  padding: 8px;
  font-family: Roboto, sans-serif;
}

.table-no-results-admin {
  border: 1px solid #ddd;
  padding: 8px;
  font-family: Roboto, sans-serif;
  color: grey;
  text-align: center;
}

.table-admin-eliminar-btn {
  background-color: rgb(242, 154, 154);
  color: black;
  margin: auto 8px;
  padding: 5px 0px;
  border: none;
  border-radius: 5px;
}

.table-admin-eliminar-btn:hover {
  background-color: rgb(241, 94, 94);
  transition: 0.2s;
  color: white;
}

.table-admin-validar-btn {
  background-color: rgb(207, 244, 170);
  color: black;
  margin: auto 8px;
  padding: 5px 0px;
  border: none;
  border-radius: 5px;
}
.table-admin-validar-btn:hover {
  background-color: rgb(130, 206, 52);
  transition: 0.2s;
  color: white;
}

/* Celular */
@media screen and (max-width: 960px) {
  .admin-home-plot {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 2rem 0;
    gap: 2rem;
  }
  .admin-home-plot-div {
    width: 100%;
  }
  .admin-title {
    font-size: 1.1rem;
  }
}

/* Celular */
@media screen and (max-width: 430px) {
  .minegocio-wrapper {
    align-items: flex-start;
  }
  .minegocio-title {
    font-size: 1.5rem;
    text-align: center;
  }
  .minegocio-subtitle {
    text-align: center;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .admin-page-link:hover {
    background: #333333;
  }
}
