.create-pag-packg-title{
    font-size: 1.3rem;
    font-weight: 500;
    color: #3b3b3b;
    margin: 0;
    margin-bottom: 1rem;
}
.create-pag-packg-p{
    color: #3b3b3b;
    margin: 0;
}
.create-pag-packg-op-flex{
    display: flex;
    padding: 1rem;
    margin-top: 1rem;
    align-items: stretch;
    justify-content: center;
}
    .create-pag-packg-option{
        border-radius: 7px;
        border: #d0d0d0 dashed 1px;
        margin: 1rem;
        width: 300px;
        padding: 2rem 1rem;
        cursor: pointer;
        text-align: center;
        transition: 0.6s;
    }
.create-pag-packg-box-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.back-button-create-pag-packg {
    background-color: #3b3b3b;
    color: white;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 0 1rem -1rem;
    width: 220px;
    font-size: 0.9rem;
    padding: 8px 12px;
    margin-left: 0.1rem;
}
.create-pag-packg-vista-prev{
    border-radius: 8px;
    border: dashed #d4d4d4 1px;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 5% 0 5%;
}

.create-pag-packg-pags-creadas{
    border: solid #d4d4d4 1px;
    padding: 1rem;
    width: 100%;
    min-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1rem;
}
.create-pag-packg-pags-creadas p{
    color: #3b3b3b;
    font-size: 1.3rem;
}

/*Extras page*/
.create-pag-articulos-extra-flexbox{
    display: flex;
    flex-wrap: wrap;
}
.create-pag-articulos-extra-elegir-tipo{
    margin: 1rem 4% 0 4%;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}
    .create-pag-articulos-extra-elegir-tipo-p{
        color: #3b3b3b;
        font-size: 1.5rem;
        margin: 0 0 0 0;
    }
.create-pag-articulos-extra-edit-wrp{
    margin: 1rem 4% 0 4%;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-wrap: wrap;
}
.create-pag-articulos-extra-edit-tipo{
    width: 100%;
    text-align: center;
    margin: 1rem 0 0 0;
    color: #3b3b3b;
}
.create-pag-articulos-extra-edit-img{
    width: 30%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
    .create-pag-articulos-extra-add-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: #d4d4d4 dashed 1px;
        padding: 2rem 1.8rem;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.3s all;
        margin-top: 2rem;
    }
    .create-pag-articulos-extra-update-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .create-pag-articulos-extra-update-img{
        color: #0D3B66;
        text-decoration: underline;
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        cursor: pointer;
    }
    .create-pag-articulos-extra-update-img p{
        margin: 10px 0 0 0;
    }
.create-pag-articulos-extra-edit-form{
    width: 70%;
}
.create-pag-articulos-extra-plus{
    width: 200px;
    height: 220px;
    border: dashed rgba(59,59,59,0.3) 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(59,59,59,0.8);
    margin: 15px;
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
    .back-button-create-pag-packg:hover {
        background-color: rgba(59, 59, 59, 0.9);
    }
    .create-pag-packg-option:hover{
        border: #0d3b66 solid 1px;
    }
    .create-pag-articulos-extra-add-img:hover{
        padding: 2.2rem 2rem;
    }
}

@media screen and (max-width: 960px){
    .create-pag-packg-op-flex{
        flex-direction: column;
        align-items: center;
    }
    .create-pag-articulos-extra-edit-wrp{
        flex-direction: column;
        padding: 0 1rem 1rem 1rem;
        margin: 1rem 1% 0 1%;
    }
    .create-pag-articulos-extra-edit-img{
        width: 200px;
    }
    .create-pag-articulos-extra-edit-form{
        width: 100%;
    }
    .create-pag-articulos-extra-plus{
        width: 130px;
        height: 220px;
        border: dashed rgba(59,59,59,0.3) 1px;
        border-radius: 10px;
        margin: 5px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 440px){
    .create-pag-articulos-extra-flexbox{
        justify-content: space-between;
    }
}