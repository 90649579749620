.template2-galeria-completa{
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    min-height: 100%;
    z-index: -1;
    background-color: #292929;
    opacity: 0;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    min-width: 322px;
}
.template2-galeria-completa.active-gallery{
    left: 0;
    opacity: 1;
    z-index: 10000;
}
.temp2-gallery-nav{
    height: 10vh;
    width: 100%;
    position: relative;
    /*border: solid red 1px;*/
}
    .temp2-gall-btns-wrp{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
    }
        .temp2-gall-btns-btn{
            height: 100%;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: white;
            font-size: 1.7rem;
            cursor: pointer;
            border-bottom: solid transparent 2px;
            user-select: none; /* standard syntax */
            -webkit-user-select: none; /* webkit (safari, chrome) browsers */
            -moz-user-select: none; /* mozilla browsers */
            -khtml-user-select: none; /* webkit (konqueror) browsers */
            -ms-user-select: none; /* IE10+ */
        }
        .temp2-gall-btns-btn.active{
            border-bottom: solid white 2px;
        }
        .temp2-gall-btns-btn p{
            font-size: 0.7rem;
            margin: 5px 0 0 0;
        }
    .temp2-gall-close-btn{
        position: absolute;
        right: 4%;
        top: 14px;
        padding: 10px 20px;
        color: white;
        background: rgba(255,255,255,0.1);
        border-radius: 5px;
        font-size: 15px;
        cursor: pointer;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }
.temp2-gallery-images-wrp{
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
    .temp2-gall-arrow{
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
        .temp2-gall-arrow-btn{
            border-radius: 99px;
            padding: 0.5rem 1.5rem;
            border: none;
            outline: none !important;
            background: rgba(255,255,255,0.1);
            color: white;
            font-size: 1.9rem;
        }
        .temp2-gall-arrow-btn:focus{
            outline: double rgba(255,255,255,0.2) 1px !important;
        }
    .temp2-gall-images{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
        .temp2-gall-imgs-control{
            /*width: 100%;*/
            /*height: 10%;*/
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none; /* standard syntax */
            -webkit-user-select: none; /* webkit (safari, chrome) browsers */
            -moz-user-select: none; /* mozilla browsers */
            -khtml-user-select: none; /* webkit (konqueror) browsers */
            -ms-user-select: none; /* IE10+ */
        }
            .temp2-gall-cont-num{
                color: white;
                margin: 0;
                padding: 5px 10px;
                letter-spacing: 2px;
                font-size: 1.1rem;
                font-family: sans-serif;
                user-select: none; /* standard syntax */
                -webkit-user-select: none; /* webkit (safari, chrome) browsers */
                -moz-user-select: none; /* mozilla browsers */
                -khtml-user-select: none; /* webkit (konqueror) browsers */
                -ms-user-select: none; /* IE10+ */
            }
            .temp2-gall-cont-btn{
                background-color: white;
                font-size: 1.7rem;
                color: #3b3b3b;
                border-radius: 99px;
                padding: 0.3rem 0.32rem 0.3rem 0.3rem;
                cursor: pointer;
                border: solid #e9e9e9 1px;
                margin: 0 5px;
            }
            .temp2-gall-cont-btn-innactive{
                background-color: rgba(255,255,255,0.7);
                font-size: 1.7rem;
                color: #818181;
                border-radius: 99px;
                padding: 0.3rem 0.32rem 0.3rem 0.3rem;
                cursor: not-allowed;
                border: solid #e9e9e9 1px;
                margin: 0 5px;
                opacity: 0.3;
                pointer-events: none;
            }
        .temp2-gall-imgs-images{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none; /* standard syntax */
            -webkit-user-select: none; /* webkit (safari, chrome) browsers */
            -moz-user-select: none; /* mozilla browsers */
            -khtml-user-select: none; /* webkit (konqueror) browsers */
            -ms-user-select: none; /* IE10+ */
        }

@media screen and (max-width: 960px){
    .temp2-gall-arrow{
        width: 5%;
    }
    .temp2-gall-images{
        width: 90%;
    }
    .temp2-gall-imgs-images{
        z-index: -1;
    }
}
@media screen and (max-width: 560px){
    .temp2-gall-arrow-btn{
        display: none;
    }
    .temp2-gallery-images-wrp{
        flex-direction: column;
    }
    .temp2-gall-arrow{
        width: 0%;
    }
    .temp2-gall-images{
        width: 100%;
    }
    .temp2-gall-imgs-images{
        margin-top: 0;
    }
    .temp2-gall-imgs-control-mobile{
        width: 100%;
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }
}
/*hover effects*/
@media(hover: hover) and (pointer: fine) {
    .temp2-gall-close-btn:hover{
        background: rgba(255,255,255,0.3);
    }
}