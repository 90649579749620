.bus-fil-wrp {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.bus-fil-input-wrp {
  border: solid var(--planodi-gray) 1px;
  background-color: white;
  color: #878787;
  display: flex;
  border-radius: 9px;
  overflow: hidden;
  padding: 0 5px;
  height: 40px;
  width: 100%;
  justify-content: space-between;
}
.bus-fil-input {
  height: 100%;
  background-color: white;
  outline: none;
  border: none;
  flex: 1;
  padding-left: 10px;
  font-size: 16px;
}

.bus-fil-search-icon {
  background-color: white;
  outline: none;
  border: none;
  /* color: #878787; */
  color: var(--planodi-gray);
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.bus-fil-search-icon:focus {
  outline: none;
}

.bus-fil-filtros-wrp-1 {
  display: flex;
  gap: 10px;
  flex: 1;
  max-width: 800px;
}
.bus-fil-filtros-wrp {
  display: flex;
  gap: 10px;
  flex: 1;
}

.bus-fil-divider-filtros {
  border-left: solid #878787 1px;
  width: 10px;
  margin: 10px 0 10px 5px;
}
/* En este break-point se ponen los filtros abajo y se quita división */
@media screen and (max-width: 1030px) {
  .bus-fil-divider-filtros {
    display: none;
  }
  .bus-fil-wrp {
    flex-direction: column;
  }
}

/* Mobile */
@media screen and (max-width: 620px) {
  .bus-fil-input {
    width: 80%;
  }
}
/* Mobile xs */
@media screen and (max-width: 450px) {
  .bus-fil-input-wrp {
    padding: 0;
    width: auto;
  }
  .bus-fil-input-wrp.open {
    width: 100%;
  }
  .bus-fil-search-icon {
    width: 40px;
    margin-left: -10px;
  }
  .bus-fil-input {
    width: 1px;
  }
  .bus-fil-input.open {
    width: 100%;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .bus-fil-search-icon:hover {
    color: #9e9e9e;
  }
}

