.div-wrp-stepper-afil{
    padding: 3rem 0 1.5rem 0;
    width: 100%;
    position: relative;
}
.afil-stepper-text-progress{
    font-size: 0.7rem;
    color: #3b3b3b;
    position: absolute;
    left: 0;
}
.afil-stepper-wrp{
    display: flex;
    justify-content: center;
}
.afil-generic-step{
    height: 0.8rem;
    width: 1rem;
    /*background-color: #3b3b3b;*/
    background-color: #8c50ff;
    opacity: 0.7;
    margin: 3px;
    border-radius: 99px;
    transition: 1s;
}
.afil-active-step{
    opacity: 1;
    width: 5rem;
    transition: 1s;
}

@media screen and (max-width: 960px){
    .div-wrp-stepper-afil{
        flex-direction: column;
    }
    .afil-stepper-text-progress{
        position: relative;
    }
}
