.modal-admin-cancel-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(241, 94, 94); /* Set the desired color of the CancelOutlinedIcon */
}

.modal-admin-cancel-btn {
  justify-content: space-between;
  display: flex;
  border-radius: 5px;
  background-color: rgb(193, 193, 193);
  color: white;
  border: none;
  justify-content: center;
}

.modal-admin-delete-confirmation-btn {
  justify-content: center;
  display: flex;
  border-radius: 5px;
  background-color: rgb(241, 94, 94);
  color: white;
  border: none;
  text-align: center;
}
