.dot-loading-wrp {
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0.2;
}

.dot-loading-wrp div {
    margin: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
}

.dot-loading-wrp-1 {
    animation-name: load-one;
    background-color: #0D3B66;
}

@keyframes load-one {
    30% {
        transform: translateY(-50%);
    }
}

.dot-loading-wrp-2 {
    animation-name: load-two;
    background-color: #8c50ff;
}

@keyframes load-two {
    50% {
        transform: translateY(-50%);
    }
}

.dot-loading-wrp-3 {
    animation-name: load-three;
    background-color: #0D3B66;
}

@keyframes load-three {
    70% {
        transform: translateY(-50%);
    }
}