.wrapper-err-msg-comp {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20% 1rem 20%;
  background-color: rgba(253, 210, 210, 0.7);
  color: #3d2323;
  border-radius: 15px;
  padding: 0.7rem 1rem 0.7rem 2rem;
  font-size: 0.8rem;
}
.err-msg-comp-exc {
  border: solid #f5574b 2px;
  color: #f5574b;
  font-size: 0.9rem;
  line-height: 0.9rem;
  border-radius: 50%;
  padding: 0 0.49rem;
  font-weight: 600;
  margin-right: 1rem;
  text-align: justify;
}

@media screen and (max-width: 960px) {
  .wrapper-err-msg-comp {
    max-width: 90%;
    margin: 0 5% 3rem 5%;
  }
}
