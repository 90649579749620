.temp2-sel-box-wrp {
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 1.7rem 1.2rem;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}
.temp2-box-discount {
  background-color: #3dbd50;
  color: white;
  font-size: 0.8rem;
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
}
.temp2-sel-box-wrp-fix {
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 1.7rem 1.2rem;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 7px;
  background-color: white;
}
.temp2-sel-box-wrp-instruction {
  margin: 0 0 15px 0;
  font-size: 0.8rem;
  color: #717171;
  text-transform: uppercase;
}
.temp2-sel-box-wrp-p-1 {
  font-size: 0.9rem;
  margin: 2px 0 0 0;
  color: #3b3b3b;
}

.temp2-sel-box-contact-info-wrp {
  margin-top: 1.5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr; /* La primera columna ocupa todo el espacio disponible */
  width: 100%;
  justify-content: flex-start;
}

.temp2-box-website-link {
  margin-left: 0.5rem;

  justify-self: flex-start;
  text-decoration: underline;
  color: black;
  cursor: pointer;
}
.temp2-box-website-link:hover {
  color: var(--planodi-purple);
  transition: 0.2s;
}

.temp2-box-email-link {
  margin: 0rem 0rem 0rem 0.5rem;
  justify-self: flex-start;
  text-decoration: underline;
  color: black;
  cursor: pointer;
}
.temp2-box-email-link:hover {
  color: var(--planodi-purple);
  transition: 0.2s;
}

.temp2-sel-box-wrp-name {
  font-size: 1rem;
  margin: 1rem 0 0 0;
  text-align: left;
  padding: 5px 0 10px 0;
  color: #3b3b3b;
}
.temp2-sel-box-wrp-desc {
  font-size: 0.8rem;
  text-align: justify;
  height: 45px;
  margin: 0 0 10px 0;
}
.temp2-sel-box-wrp-images {
  font-size: 0.85rem;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  color: #757575;
}
.temp2-sel-box-wrp-images:hover {
  color: #484848;
}
.temp2-sel-box-wrp-slide {
  height: 2px;
  width: 60px;
  border-radius: 99px;
  background-color: #8c8c8c;
  margin-bottom: 5px;
}
.temp2-sel-box-buttn-mobile {
  width: 50%;
  margin-top: 12px;
  padding: 0 0 0 10px;
}
.temp2-sel-box-redes-s-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #3b3b3b;
  margin-bottom: 5px;
  height: 1.5rem;
}
.temp2-sel-box-redes-s-wrp-icon {
  margin: 0 10px;
  cursor: pointer;
}

/* Celular */
@media screen and (max-width: 960px) {
  .temp2-sel-box-wrp {
    margin: 0;
    box-shadow: none;
    padding: 0;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .temp2-sel-box-wrp-instruction {
    font-size: 0.52rem;
    margin-bottom: 5px;
    width: 100%;
  }
  .temp2-sel-box-wrp-price {
    font-weight: 500;
    font-size: 1.1rem;
    white-space: nowrap;
  }
  .temp2-sel-box-wrp-name {
    font-size: 0.8rem;
    padding: 0 0 0 8px;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .temp2-sel-box-wrp-p-1 {
    font-size: 0.8rem;
    margin: 2px 0 0 0;
    color: #3b3b3b;
    font-weight: 300;
  }
  .temp2-box-website-link {
    font-size: 1rem;
    margin: 0px;
    color: rgb(59, 59, 59);
  }
  .temp2-box-website-link:hover {
    color: rgb(59, 59, 59);
    opacity: 0.9;
  }
  .temp2-box-email-link {
    color: rgb(59, 59, 59);
    font-size: 1rem;
    margin: 0px;
  }
  .temp2-box-email-link:hover {
    color: rgb(59, 59, 59);
    opacity: 0.9;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .temp2-sel-box-redes-s-wrp-icon:hover {
    opacity: 0.9;
  }
}

