.google-dropdown-div {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  background-color: white;
  border: solid 1px black;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.google-dropdown-wrapper {
  display: flex;
  cursor: pointer;
  color: black;
  position: relative;
  border-top: 1px solid black;
}

.google-dropdown-icon-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  align-content: center;
}

.google-dropdown-icon {
  width: 20px;
  height: 20px;
  color: var(--planodi-purple);
}

.google-dropdown-option {
  color: rgb(124, 124, 124);
  align-items: center;
  display: flex;
  margin: 7px 1px;
}

.google-dropdown-option:hover {
  color: var(--planodi-purple);
}
