/*Titulo*/
.temp2-wrapper-parent {
  overflow: auto;
}
.temp2-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 322px;
}
.temp2-wrapper-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  /*max-width: 1400px;*/
  min-width: 320px;
  width: 100%;
  margin: 0;
}
.temp2-box-selected-mobile {
  display: none;
}
.temp2-wrapper::-webkit-scrollbar {
  width: 0;
}
.temp2-wrapper-parent::-webkit-scrollbar {
  width: 0;
}
.temp2-top-nav-mobile {
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
}

.temp2-content-wrp {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  min-width: 320px;
  width: 100%;
  padding: 0 100px;
}

.temp2-title-wrp-flex {
  width: 100%;
  position: relative;
  min-height: 400px;
  margin-bottom: 1rem;
  align-items: center;
}
.temp2-wrp-flex-logo-titulo {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  /*border: solid red 1px;*/
}
.temp2-logo-negocio {
  height: 4rem;
  width: 4rem;
  border-radius: 99px;
  -webkit-box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  margin: 5px 8px 5px 5px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}
.temp2-title {
  color: #3b3b3b;
  font-weight: 500;
  font-size: 1.7rem;
  text-align: left;
  margin: 0.2rem 0 0 0;
  display: flex;
  align-items: center;
  /*border: solid blue 1px;*/
}
.temp2-pending-title-span {
  display: inline-block;
  margin: 0rem 0rem 0rem 1rem;
}
.temp2-pending-title-tooltip {
  display: flex;
  align-items: center;
  background-color: var(--planodi-purple);
  opacity: 0.8;
  color: white;
  border-radius: 10px;
  padding: 2px 12px;
}
.temp2-pending-title-tooltip p {
  font-size: 20px;
  text-align: center;
  margin: 0;
}

.temp2-wrp-general-info {
  display: flex;
}
.temp2-subtitle {
  text-align: left;
  font-size: 0.9rem;
  margin: 0;
  color: #3b3b3b;
}
.temp2-dot {
  font-size: 0.9rem;
  padding: 0;
  margin: 0 3px;
  color: #3b3b3b;
}
.temp2-subtitle-comments {
  font-size: 0.8rem;
  color: #696767;
  margin-left: 5px;
}

/*
Sección galería
*/
.temp2-galeria {
  width: 100%;
  padding: 0.7rem 0;
  cursor: pointer;
}
.temp2-galeria-fotos-5img {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  position: relative;
}
.temp2-galeria-fotos-3img {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  position: relative;
}
.temp2-box {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  transition: 0.5s;
}
.temp2-box1-3img {
  grid-column: 1/3;
  grid-row: 1/3;
  border-radius: 10px 0 0 10px;
}
.temp2-box2-3img {
  grid-column: 3/4;
  grid-row: 1/2;
  border-radius: 0 10px 0 0;
}
.temp2-box3-3img {
  grid-column: 3/4;
  grid-row: 2/3;
  border-radius: 0 0 10px 0;
}
.temp2-box1 {
  grid-column: 1/3;
  grid-row: 1/3;
  border-radius: 10px 0 0 10px;
}
.temp2-box2 {
  grid-column: 3/4;
  grid-row: 1/2;
}
.temp2-box3 {
  grid-column: 3/4;
  grid-row: 2/3;
}
.temp2-box4 {
  grid-column: 4/5;
  grid-row: 1/2;
  border-radius: 0 10px 0 0;
}
.temp2-box5 {
  grid-column: 4/5;
  grid-row: 2/3;
  border-radius: 0 0 10px 0;
}

.temp2-boton-galeria {
  border-radius: 5px;
  /*background-color: white;*/
  position: absolute;
  bottom: 4%;
  left: 2%;
  opacity: 1;
  transition: 0.2s;
  background: linear-gradient(282.63deg, rgba(255, 255, 255, 0.44) 24.04%, #ffffff 79.72%);
  backdrop-filter: blur(5px);
}
.temp2-btn-texto {
  font-size: 0.8rem;
  margin: 0.5rem 0.5rem;
}

/*Contenido general*/
.temp2-grid-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
}
.temp2-info-wrp {
  grid-column: 1/4;
}
.temp2-selected-wrp {
  grid-column: 4/6;
  padding: 7px;
  display: flex;
}

/*back button*/
.temp2-back-mobile-button {
  height: 100%;
  display: table;
  padding: 8px;
  width: 120px;
  margin-left: 1%;
}

.temp2-back-mobile-button-p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-size: 0.9rem;
  cursor: pointer;
}

/*General info*/
.temp2-general-inf-wrp {
  width: 100%;
  margin-top: 2rem;
  padding: 0 10px 0 0;
}
.temp2-general-inf-title {
  margin: 0 0 5px 0;
  font-size: 1.5rem;
  color: #3b3b3b;
}
.temp2-general-inf-p {
  margin: 0;
  font-size: 0.9rem;
  text-align: justify;
}
.temp2-general-inf-faq {
  margin: 1.1rem 0 2px 0;
  font-size: 0.9rem;
  text-align: justify;
  font-weight: 600;
  color: #3b3b3b;
}
.temp2-info-terrazas-icons-wrp {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.temp2-gall-mobile-wrp {
  display: none;
}

.temp2-video-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.temp2-video-section-button {
  background: rgba(40, 40, 40, 0.8);
  position: absolute;
  left: 50%;
  height: 100%;
  width: 560px;
  transform: translateX(-50%);
  cursor: pointer;
}
.temp2-video-section-button-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.temp2-video-section-button-p {
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  text-decoration: underline;
}
.temp2-video-section-button-icono {
  color: white;
  font-size: 4.9rem;
}
/* Celular */
@media screen and (max-width: 960px) {
  .temp2-content-wrp {
    padding: 0 5%;
  }
  .temp2-wrapper {
    align-items: flex-start;
    /*height: 80vh;*/
    box-sizing: border-box;
    overflow-y: scroll;
  }
  .temp2-box-selected-mobile {
    z-index: 1000;
    display: block;
    width: 100%;
    min-width: 320px;
    height: 80px;
    border: solid #e1e1e1 1px;
    box-sizing: border-box;
    margin: 0;
    position: fixed;
  }
  .temp2-title {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .temp2-subtitle {
    font-size: 0.8rem;
  }
  /*Contenido general*/
  .temp2-grid-content {
    grid-template-columns: 1fr;
  }
  .temp2-info-wrp {
    grid-column: 1/2;
    overflow: hidden;
  }
  .temp2-selected-wrp {
    display: none;
  }
  .temp2-general-inf-title {
    font-size: 1.3rem;
  }
}

/* galeria grid 3 columnas*/
@media screen and (max-width: 780px) {
  .temp2-galeria-fotos-5img {
    grid-template-columns: repeat(3, 1fr);
  }
  .temp2-box1 {
    grid-column: 1/3;
    grid-row: 1/3;
    border-radius: 10px 0 0 10px;
  }
  .temp2-box2 {
    grid-column: 3/4;
    grid-row: 1/2;
    border-radius: 0 10px 0 0;
  }
  .temp2-box3 {
    grid-column: 3/4;
    grid-row: 2/3;
    border-radius: 0 0 10px 0;
  }
  .temp2-box4 {
    display: none;
  }
  .temp2-box5 {
    display: none;
  }
}
/* galeria grid 1 columna*/
@media screen and (max-width: 580px) {
  /*big gallery*/
  .temp2-galeria-fotos-5img {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .temp2-box1 {
    grid-column: 1/2;
    grid-row: 1/2;
    border-radius: 10px;
  }
  .temp2-box2 {
    display: none;
  }
  .temp2-box3 {
    display: none;
  }
  .temp2-box4 {
    display: none;
  }
  .temp2-box5 {
    display: none;
  }

  /*Small gallery*/
  .temp2-galeria-fotos-3img {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .temp2-box1-3img {
    grid-column: 1/2;
    grid-row: 1/2;
    border-radius: 10px;
  }
  .temp2-box2-3img {
    display: none;
  }
  .temp2-box3-3img {
    display: none;
  }
  .temp2-gall-mobile-wrp {
    display: block;
    width: 100%;
    height: auto;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .temp2-gall-mobile-counter-img {
    position: absolute;
    right: 0.8rem;
    bottom: 0.8rem;
    background: rgba(59, 59, 59, 0.6);
    border-radius: 99px;
    font-size: 0.7rem;
    color: white;
  }
  .temp2-gall-mobile-counter-img p {
    margin: 0;
    margin: 3px 13px;
  }
  .temp2-gall-mobile-wrp::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  .temp2-gall-mobile-flex {
    display: flex;
    height: auto;
  }
  .temp2-gall-mobile-img {
    width: 100% !important;
    height: 300px;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    scroll-snap-align: start;
  }
}

/*@media screen and (max-width: 450px) {*/
/*  .temp2-wrapper {*/
/*    height: 80vh;*/
/*  }*/
/*  .temp2-box-selected-mobile {*/
/*    height: 20vh;*/
/*    border-radius: 10px 10px 0 0;*/
/*  }*/
/*}*/

/* max titulo*/
@media screen and (max-width: 390px) {
  .temp2-title {
    max-width: 300px;
  }
}

/* Max screen */
@media screen and (min-width: 1400px) {
  .temp2-content-wrp {
    align-items: center;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .temp2-box1:hover,
  .temp2-box2:hover,
  .temp2-box3:hover,
  .temp2-box4:hover,
  .temp2-box5:hover {
    -webkit-filter: brightness(88%);
    filter: brightness(88%);
  }
  .temp2-boton-galeria:hover {
    background: linear-gradient(282.63deg, rgba(255, 255, 255, 0.44) 10%, #ffffff 90%);
    backdrop-filter: blur(2px);
  }
  .temp2-video-section-button:hover .temp2-video-section-button-icono {
    color: #ff0000;
  }
  .temp2-pending-title-tooltip:hover {
    transition: 0.2s;
    opacity: 1;
    cursor: default;
  }
}

