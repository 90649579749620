.wrp-articulo{
    width: 180px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    border-radius: 10px;
    margin: 15px 8px;
    justify-content: flex-start;
    position: relative;
}
    .articulo-edit-pencil{
        position: absolute;
        top:0;
        right: 0;
        background: #3b3b3b;
        color: white;
        border-radius: 99px;
        z-index: 1000;
        padding: 0.2rem 0.5rem;
    }
    .articulo-foto{
        width: 100%;
        height: 130px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;

    }
        .articulo-foto-img{
            position: absolute;
            width: 100%;
            min-height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    .articulo-titulo{
        font-size: 0.9rem;
        color: #3b3b3b;
        line-height: 1rem;
        margin: 0.8rem 0 0 0;
        font-weight: 400;
    }
    .articulo-controlador-wrp{
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        /*border: solid red 1px;*/
        align-self: center;
        border-radius: 99px;
        margin: 0.8rem 0 0 0;
        -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
        color: #0D3B66;
        font-weight: 500;
        overflow: hidden;
    }
    .articulo-controlador-wrp-btn{
        text-align: center;
        width: 33.3%;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        transition: 0.2s;
    }
    .articulo-texto-desc{
        font-size: 0.8rem;
        color: #3b3b3b;
        text-align: justify;
        margin-top: 10px;
        line-height: 0.84rem;
    }
.articulo-controlador-servicio-wrp{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.09);
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.09);
    font-weight: 500;
}

/* Celular */
@media screen and (max-width: 960px){
    .wrp-articulo{
        width: 130px;
        margin: 8px 3px;
    }
}

/*hover effects*/
@media(hover: hover) and (pointer: fine) {
    .articulo-controlador-wrp-btn:hover{
        color: white;
        background-color: #0D3B66;
    }
}