.edit-extras_titulo{
    font-size: 1rem;
    color: #3b3b3b;
    font-weight: 300;
    text-align: left;
}
.edit-extras-instruc{
    text-align: center;
    font-size: 1rem;
}
.edit-extras-precio-slider-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.edit-extras-precio-slider{
    width: 300px;
}
.edit-extras-precio-slider-p{
    color: #3b3b3b;
    font-weight: 500;
    margin: 0;
    font-size: 1.5rem;
}
.edit-extras-precio-slider-p2{
    color: #585858;
    font-weight: 300;
    margin: 0;
    font-size: 0.8rem;
}

.edit-extras_soc_btns{
    display: flex;
    width: 100%;
    margin-top: 2rem;
    justify-content: flex-end;
}

/* Celular */
@media screen and (max-width: 960px){
    .edit-extras_soc_btns{
        flex-direction: column-reverse;
    }
}