.carouselV2-wrp {
  width: 100%;
  overflow-x: scroll;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50px;
}
.carouselV2-wrp::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.carouselV2-title-wrp {
  display: flex;
}
.carouselV2-title {
  margin: 0 0 5px 0;
  font-size: 1.3rem;
  color: #3b3b3b;
}
.carouselV2-control-wrp {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.carouselV2-control-btn {
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 1.7rem;
  color: #3b3b3b;
  border-radius: 99px;
  padding: 0.3rem 0.32rem 0.3rem 0.3rem;
  cursor: pointer;
  border: solid #e9e9e9 1px;
  margin: 0 5px;
}
.carouselV2-control-btn-innactive {
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 1.7rem;
  color: #818181;
  border-radius: 99px;
  padding: 0.3rem 0.32rem 0.3rem 0.3rem;
  cursor: not-allowed;
  border: solid #e9e9e9 1px;
  margin: 0 5px;
  opacity: 0.3;
  pointer-events: none;
}
.carouselV2-control-pagination {
  font-size: 0.8rem;
  display: inline;
  margin-right: 5px;
  font-family: Roboto, sans-serif;
}

.carouselV2-content-blur {
  position: absolute;
  right: 0;
  bottom: 1rem;
  height: 200px;
  width: 145px;
  z-index: 1000;
  border-radius: 15px 0 0 15px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.carouselV2-content {
  display: flex;
  justify-content: space-between;
  width: 3000px;
}
.carouselV2-content-active-element {
  border-radius: 15px;
}

.carouselV2-ver-mas-btn {
  width: 220px;
  margin-right: 20px;
  height: 250px;
  border: solid rgba(59, 59, 59, 0.3) 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(59, 59, 59, 0.8);
}

/* Celular */
@media screen and (max-width: 960px) {
  .carouselV2-content-blur {
    display: none;
  }
  .carouselV2-title {
    font-size: 1.2rem;
    width: 290px;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .carouselV2-control-btn:hover {
    padding: 0.36rem 0.38rem 0.36rem 0.36rem;
    background-color: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
  }
}
