.editar_red_soc_titulo {
	font-size: 1rem;
	color: #3b3b3b;
	font-weight: 300;
	text-align: left;
}
.editar_red_soc_desc {
	margin: 0 0 1rem 0;
	color: #3b3b3b;
}
.editar_red_soc_input_desc {
	margin: 0;
	color: #3b3b3b;
	font-weight: 400;
}
.editar_red_soc_btns {
	display: flex;
	width: 100%;
	margin-top: 2rem;
	justify-content: flex-end;
}

/* Celular */
@media screen and (max-width: 960px) {
	.editar_red_soc_btns {
		flex-direction: column-reverse;
	}
}
