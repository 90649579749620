.fontLanding {
  font-family: 'Inter', Roboto, sans-serif;
}

.menuIcon {
  display: none;
}

.menuIcon:focus {
  outline: none;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  background-color: transparent;
  height: 60px;
  padding: 0 !important;
  width: 100%;
  min-width: 320px;
  max-width: 1400px;
  z-index: 1002;
  transition: 0.5s;
}
.navbar-relative {
  position: static;
  top: 0;
  background-color: transparent;
  height: 60px;
  padding: 0 !important;
  width: 100%;
  min-width: 320px;
  z-index: 1002;
}
.navbar-fixed.active {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.nav-flex-wrp {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
}
.img-logo-nav {
  margin: -5px 2rem 0 2rem;
  max-width: 7rem;
}

.nav-location-btn {
  color: #0d3b66;
  border-right: solid rgb(230, 235, 241) 1px;
  border-left: solid rgb(230, 235, 241) 1px;
  padding: 7px 15px 7px 13px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.style-solid-navbar {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  min-width: 320px;
}

.nav-menu {
  position: absolute;
  width: 100%;
  top: -200%;
  opacity: 0;
  transition: all 0.3s ease-out;
  border-radius: 0 0 10px 10px;
}

.boton-acciones {
  padding: 0.22rem 0.32rem;
  display: flex;
  color: #393737;
  border-radius: 999px;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 50%)
    right;
  background-size: 200%;
  transition: 0.3s ease-out;
  cursor: pointer;
  align-items: center;
  /*border: solid #f3f1f1 1px;*/
  -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.03);
}
.boton-acciones:hover {
  background-position: left;
  -webkit-box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.13);
}

.acciones {
  font-size: 0.8rem;
  padding: 1.2rem 1rem !important;
  width: 100%;
  margin: 0;
}

.acciones:hover {
  background-color: #efefef;
}

.bold {
  font-weight: 600;
}

.menu-acciones {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  background-color: white;
  border-radius: 10px;
  width: 13rem;
  display: none;
  z-index: 102;
  -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.menu-acciones.active {
  display: block;
}

.menu-items {
  text-align: center;
  margin: 1rem;
}

.botones-desktop {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.navbar-opciones-centro {
  cursor: pointer;
  display: inline;
  font-size: 0.9rem;
  padding: 0.3rem 1.1rem;
  text-decoration: none;
  color: #3f3f3f;
}
.navbar-opciones-centro.active {
  color: #3f3f3f;
}
.navbar-opciones-centro.active:hover {
  color: #3f3f3f;
  font-weight: 500;
  border-bottom: none;
}

.navbar-opciones-centro:hover {
  text-decoration: none;
  color: #3f3f3f;
  border-bottom: white 2px solid;
}
.button-nav-publicar {
  cursor: pointer;
  display: inline;
  font-size: 0.9rem;
  padding: 0.3rem 1.1rem;
  text-decoration: none;
  /* background-color: rgba(13, 59, 102, 0.9); */
  color: #3f3f3f;
  border-radius: 9px;
  margin-left: 5px;
}

/*cambio a celular*/
@media screen and (max-width: 960px) {
  .img-logo-nav {
    margin-left: 4%;
  }
  .botones-desktop {
    right: 1rem;
  }
  .menuIcon:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .nav-menu.active {
    background: rgb(255, 255, 255);
    top: 0;
    opacity: 1;
    width: 100%;
    transition: all 0.3s ease-in;
    z-index: 1;
  }
}

