.modal-contact-message-title {
  font-size: 22px;
  margin: 0px 0px 20px 0px;
}
.modal-contact-message-subtitle {
  margin: 10px 0px 0px 0px;
  font-size: 16px;
}

.modal-contact-main-options-wrp {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
}

.modal-contact-inner-options-wrp {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-contact-inner-option {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(233, 240, 252);
  border-radius: 10px;
  padding: 8px 10px;
  align-items: center;
}

.icon-text-container {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.modal-contact-inner-option-p {
  margin: 0;
  max-width: 300px;
  font-size: 15px;
}
.modal-contact-inner-option-copied {
  color: var(--planodi-purple);
  font-size: 14px;
}

.icon-copy-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-right: 20px;
}

.modal-cotact-icon-whatsapp {
  cursor: pointer;
  color: green;
}

.modal-cotact-icon-whatsapp:hover {
  opacity: 100%;
  transition: 0.2s;
}

.modal-cotact-icon-copy {
  cursor: pointer;
  opacity: 60%;
}

.modal-cotact-icon-copy:hover {
  transition: 0.2s;
  opacity: 100%;
}

/* Celular */
@media screen and (max-width: 960px) {
  .modal-contact-message-title {
    font-size: 16px;
  }
  .modal-contact-message-subtitle {
    font-size: 14px;
  }
  .modal-contact-inner-options-wrp {
    margin: 10px 0;
  }
  .modal-contact-inner-option-p {
    margin: 0;
    max-width: 180px;
    font-size: 14px;
  }
  .modal-contact-inner-option {
    border-radius: 5px;
    padding: 8px 5px;
  }
  .icon-copy-container {
    margin-right: 5px;
  }
  .icon-text-container {
    margin-left: 5px;
    gap: 10px;
  }
}

