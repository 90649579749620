.site-docs-section-class {
  background-color: #f2f2f2;

  order: 3;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.site-docs-section-class ul li a {
  text-decoration: none !important;
}

.site-docs-section-container {
  container: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.site-docs-section-ul {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 19px;
  padding-left: 0rem;

  @media (min-width: 640px) {
    gap: 21px;
  }

  @media (min-width: 768px) {
    gap: 24px;
  }

  @media (min-width: 1024px) {
    gap: 19px;
  }

  @media (min-width: 1280px) {
    gap: 21px;
  }

  @media (min-width: 1536px) {
    gap: 24px;
  }
}

.site-docs-section-li {
  padding-left: 16px;
  padding-right: 16px;
  border-bottom-width: 4px;
  border-bottom: 4px solid transparent;
  transition: padding 0.2s, border-bottom 0.3s;
}

.site-docs-section-selected-link {
  border-bottom: 4px solid var(--planodi-purple);
}

.site-docs-section-text {
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: #001f3f !important;
  margin: 15px 0px;
}

.site-docs-document-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
  color: var(--planodi-purple);

  @media (min-width: 640px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 768px) {
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (min-width: 1024px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1280px) {
    padding-left: 12%;
    padding-right: 12%;
  }

  @media (min-width: 1536px) {
    padding-left: 14%;
    padding-right: 14%;
  }
}

.site-docs-document-title-text {
  font-family: 'Inter', sans-serif;
  font-weight: bold;

  font-size: 32px;
  padding-top: 01rem;

  @media (max-width: 640px) {
    font-size: 25px;
    padding-top: 01vw;
  }

  @media (min-width: 640px) {
    font-size: 32px;
  }

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }

  @media (min-width: 1280px) {
    font-size: 32px;
  }

  @media (min-width: 1536px) {
    font-size: 32px;
  }
}

.site-docs-document-rectangle-div {
  width: 60px;
  height: 4px;
  background-color: var(--planodi-purple);

  @media (min-width: 640px) {
    width: 70px;
  }

  @media (min-width: 768px) {
    width: 80px;
  }

  @media (min-width: 1024px) {
    width: 60px;
  }

  @media (min-width: 1280px) {
    width: 70px;
  }

  @media (min-width: 1536px) {
    width: 80px;
  }
}

.site-docs-document-custom-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0rem;
  padding-bottom: 0rem;
  display: grid;
  min-height: 75vh;

  @media (min-width: 640px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 768px) {
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (min-width: 1024px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: 1280px) {
    padding-left: 12%;
    padding-right: 12%;
  }

  @media (min-width: 1536px) {
    padding-left: 14%;
    padding-right: 14%;
  }
}
