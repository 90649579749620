.cardProveedores-container {
  width: 90%;
  margin: 50px auto 0 auto;
}

.cardProveedores-title {
  font-size: 1.8rem;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
}
.cardProveedores-subtitle {
  font-size: 1.1rem;
  color: #818181;
  margin: 5px 0 40px 0;
}

.cardProveedores-main-wrp {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 20px;
  row-gap: 15px;
  margin-bottom: 30px;
}

.cardProveedores-ver-mas-btn {
  justify-content: center;
  width: 90%;
  margin: 0px auto;
  justify-content: center;
  height: 230px;
  border: solid rgba(59, 59, 59, 0.3) 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(59, 59, 59, 0.8);
}

@media (hover: hover) and (pointer: fine) {
  .cardProveedores-ver-mas-btn:hover {
    /* padding: 0.36rem 0.38rem 0.36rem 0.36rem; */
    background-color: rgb(245, 245, 245);
  }
}

@media screen and (max-width: 550px) {
  .cardProveedores-title {
    font-size: 1.3rem;
  }
  .cardProveedores-subtitle {
    font-size: 0.95rem;
    margin: 5px 0 20px 0;
  }
}

