.sel-plan-wrapper {
	display: flex;
	gap: 20px;
	justify-content: stretch;
}
.sel-plan-card {
	border-radius: 10px;
	width: 33.3333%;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	overflow: hidden;
	border: solid transparent 3px;
	-webkit-box-shadow: 0 0 14px -10px rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 14px -10px rgba(0, 0, 0, 0.6);
}
.sel-plan-card.active {
	border: solid #8c50ff 3px;
}
.sel-plan-header {
	height: 60px;
	pointer-events: none;
	border-bottom: solid 1px rgb(240, 240, 240);
}
.sel-plan-header-gratis {
	background: url("../../../Assets/img/Imagenes_Planes/Plan_1.webp");
	background-repeat: no-repeat;
	background-size: auto 180%;
	background-position: 105% 40%;
}
.sel-plan-header-premium {
	background: url("../../../Assets/img/Imagenes_Planes/Plan_2.webp");
	background-repeat: no-repeat;
	background-size: auto 200%;
	background-position: 105% 30%;
}
.sel-plan-header-pro {
	background: url("../../../Assets/img/Imagenes_Planes/Plan_3.webp");
	background-repeat: no-repeat;
	background-size: auto 200%;
	background-position: 100% 30%;
}
.sel-plan-header-p {
	font-size: 2rem;
	margin: 10px 0 0 10px;
}
.sel-plan-contenido {
	padding: 30px;
}
.sel-plan-contenido-periodo {
	font-weight: 300;
	font-size: 0.8rem;
	text-align: center;
	margin: 0;
}
.sel-plan-contenido-precio {
	font-weight: 500;
	font-size: 1.5rem;
	text-align: center;
	margin: 0;
}
.sel-plan-curr-sel {
	margin: 35px 0 0 0;
	text-align: center;
	font-size: 20px;
	font-weight: 400;
}

/*mobile*/
@media (max-width: 960px) {
	.sel-plan-wrapper {
		flex-direction: column;
	}
	.sel-plan-card {
		width: 100%;
	}
}

/* .div-wrapper-sel-plan {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.div-wrapper-plans {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 10px 0 0;
}
.div-wrp-selected-plan-info {
  width: 50%;
  padding: 10px;
}
.div-wrp-selected-plan-info-content {
  border-radius: 10px;
  border: solid #f3f1f1 1px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-selection-box {
  width: 100%;
  height: 100px;
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 15px;
  color: #3b3b3b;
  font-size: 1.7rem;
  font-weight: 400;
  border: solid transparent 3px;
  -webkit-box-shadow: 0 0 0 1px #f6f3f3;
  box-shadow: 0 0 0 1px #f6f3f3;
}
.plan-selection-box:hover {
  -webkit-box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.13);
}
.plan-selection-box.active {
  border: solid #8c50ff 3px;
}

.plan-gratis-selection {
  background: url('../../../Assets/img/Imagenes_Planes/Plan_1.webp');
  background-repeat: no-repeat;
  background-size: auto 300%;
  background-position: 115% 40%;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}

.plan-plus-selection {
  background: url('../../../Assets/img/Imagenes_Planes/Plan_3.webp');
  background-repeat: no-repeat;
  background-size: auto 229%;
  background-position: 105% 29%;
}
.afil-sel-plan-titulo-info {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  color: #3b3b3b;
  font-weight: 500;
  background-color: #e1c7fd;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
}
.afil-sel-plan-titulo-info p {
  margin: 0;
}
.afil-sel-plan-contenido-info {
  margin-top: 1rem;
  font-size: 0.9rem;
  width: 300px;
  color: #3b3b3b;
  text-align: center;
}
.afil-sel-beneficios-p {
  font-size: 1rem;
}

@media (max-width: 960px) {
  .afil-sel-beneficios-p {
    font-size: 0.8rem;
  }
  .div-wrapper-plans {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .plan-selection-box {
    height: auto;
    font-size: 1rem;
  }
  .plan-selection-box.active {
    font-size: 2rem;
  }
  .plan-gratis-selection {
    background-size: auto 300%;
    background-position: 100% 40%;
  }
  .plan-plus-selection {
    background-position: 105% 29%;
  }
}

@media (max-width: 478px) {
  .plan-gratis-selection {
    background-size: auto 200%;
    background-position: 100% 30%;
  }
  .plan-plus-selection {
    background-size: auto 110%;
    background-position: 115% -100%;
  }
} */
