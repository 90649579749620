.restore-psw-main-form-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 35% 15% 35%;
  max-width: 0% 35%;
}

.restore-psw-main-form-title {
  font-size: 20px;
}
