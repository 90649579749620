.admin-layout-div1 {
    position: relative;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-layout-div2 {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    min-width: 320px;
    width: 100%;
    padding: 0 5%;
    margin: 0;
}