.buscador-grid-wrp {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.buscador-packages-grid-wrp {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.buscador-grid-clear-filters {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .buscador-packages-grid-wrp {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
