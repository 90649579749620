.alaido-form-label1{
    font-size: 1.2rem;
    font-weight: 500;
    color: #222222;
    margin: 0;
}
.alaido-form-label2{
    font-size: 0.8rem;
    font-weight: 400;
    color: #545454;
    margin: 0;
}

.afil-botonAtras{
    position: absolute !important;
    top:-3.4rem;
    left: 2%;
}
.afil-botonAtras:focus{
    outline: none;
}