.modal-country-title {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
}

.modal-country-row {
  display: flex;
  padding: 20px;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-country-subtitle-wrapper {
  display: flex;
  justify-content: left;
}

.modal-country-subtitle {
  color: #3b3b3b;
  font-size: 16px;
  margin: 10px 0 0 0;
}

.modal-country-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  color: #3b3b3b;
  font-size: 14px;
  background-color: #fafafa;
  padding: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 5px;
}
.modal-country-card:hover {
  transition: 0.3s ease-in-out;
  background-color: #f6f6f6;
}
.modal-country-card.active {
  border: 1px solid #5c5c5c;
  background-color: #fafafa;
}

.modal-country-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

