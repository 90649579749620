.cardPackages-container {
  width: 90%;
  margin: 110px auto 0 auto;
}
.cardPackages-main-title {
  font-size: 1.8rem;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0 0 0 0;
}
.cardPackages-main-subtitle {
  font-size: 1.1rem;
  color: #818181;
  margin: 5px 0 20px 0;
}

.cardPackages-main-wrp {
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  column-gap: 26px;
  row-gap: 26px;
  overflow: hidden;
  padding-bottom: 10px;
}

@media screen and (max-width: 550px) {
  .cardPackages-container {
    margin: 70px auto 0 auto;
  }
  .cardPackages-main-wrp {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  .cardPackages-main-title {
    font-size: 1.3rem;
  }
  .cardPackages-main-subtitle {
    font-size: 0.95rem;
  }
}

