.afil-wrapper {
  position: relative;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.afil-wrapper::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.afil-content-wrp {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  min-width: 320px;
  width: 100%;
  padding: 0 5%;
}
.afil-personaje {
  width: 25rem;
  position: fixed;
  bottom: -2rem;
  right: -3rem;
}

.afil-link {
  font-family: sans-serif;
  font-size: 1.15rem;
  font-weight: 300;
  color: #a2a1a1;
}
.afil-link2 {
  font-family: sans-serif;
  font-size: 1.15rem;
  color: #404040;
  font-weight: 500;
  display: inline;
}

.afil-link-wrapper {
  padding: 1.3rem 1rem 1rem 1rem;
}
.postion-rel {
  position: relative;
}
.helper-text-input {
  max-width: 15rem;
  color: white;
  font-size: 0.8rem;
  border-radius: 20px 20px 20px 0;
  padding: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-95%);
  background-color: rgba(102, 40, 239, 0.95);
  z-index: 100;
  margin: 0;
}
.afil-help-text {
  margin-top: 1rem;
  width: 90%;
  font-family: sans-serif;
  font-size: 0.8rem;
  color: #3b3b3b;
  text-align: center;
  border-top: #e7e2e2 1px solid;
  padding: 1rem 0;
}

/* Celular */
@media screen and (max-width: 960px) {
  .afil-wrapper {
    align-items: flex-start;
  }
  .afil-link-wrapper {
    padding: 0.6rem calc(10% + 1rem);
  }
  .helper-text-input {
    border-radius: 20px;
    max-width: 100% !important;
    transform: translateY(-100%);
  }
}

/* Max screen */
@media screen and (min-width: 1400px) {
  .afil-content-wrp {
    align-items: center;
  }
}

