.tipo-eventos-blur-wrp {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}
.tipo-ev-blur-wrp-cont {
  width: 90%;
  overflow: hidden;
}
.tipo-ev-blur-wrp-title {
  font-size: 1.3rem;
  font-weight: 400;
  display: inline;
  margin-right: 1rem;
}
.tipo-ev-blur-wrp-cont-flex {
  margin-top: 1rem;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  display: flex;
  justify-content: space-between;
}
.tipo-ev-blur-wrp-cont-flex::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.tipo-ev-blur-wrp-cont-flex-div {
  flex-shrink: 0;
  width: 25%;
  min-width: 300px;
  scroll-snap-align: start;
}
.tipo-ev-blur-wrp-cont-flex-div2 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  height: 120px;
  cursor: pointer;
  position: relative;
}
.tipo-ev-blur-wrp-cont-flex-div2-img {
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  transition: 1s;
  border-radius: 10px;
}
.tipo-ev-blur-wrp-cont-flex-div2-img2 {
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center 60% !important;
  transition: 1s;
  border-radius: 10px;
}
.tipo-ev-blur-wrp-cont-flex-p {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  border-radius: 10px 0 10px 0;
  text-align: center;
  font-size: 0.9rem;
  color: #3b3b3b;
  transition: 0.5s;
  background: linear-gradient(
    303.62deg,
    rgba(255, 255, 255, 0.51) 55.87%,
    rgba(255, 255, 255, 0.92) 139.83%
  );
  backdrop-filter: blur(10px);
  margin: 0;
}
.tipo-ev-blur-wrp-cont-flex-p-vermas {
  position: absolute;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
  transition: 0.5s;
  margin: 0;
  border-radius: 10px;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  color: #3b3b3b;
  width: 90%;
}
.tipo-ev-blur-wrp-cont-flex-explore {
  align-items: center;
  justify-content: center;
  /*background: linear-gradient(303.62deg, rgba(140, 80, 255, 0.14) 55.87%, rgba(78, 23, 185, 0.16) 139.83%);*/
  /* border: solid rgba(59, 59, 59, 0.7) 1px; */
}

@media screen and (max-width: 1036px) {
  .tipo-ev-blur-wrp-cont-flex {
    padding-right: 230px;
  }
}

/*mobile*/
@media screen and (max-width: 960px) {
  .tipo-ev-blur-wrp-cont-flex-p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 600px) {
  .tipo-ev-blur-wrp-cont {
    width: 100%;
    padding-left: 5%;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .tipo-ev-blur-wrp-cont-flex-div2:hover .tipo-ev-blur-wrp-cont-flex-div2-img {
    transform: scale(1.02);
  }
  .tipo-ev-blur-wrp-cont-flex-div2:hover .tipo-ev-blur-wrp-cont-flex-div2-img2 {
    transform: scale(1.02);
  }
  .tipo-ev-blur-wrp-cont-flex-div2:hover #ver-mas-home {
    color: rgba(59, 59, 59, 0.7) !important;
  }
}

