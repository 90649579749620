.main-wrapper-ed-nego {
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
}
.second-wrapper-ed-nego {
  max-width: 1500px;
  min-width: 320px;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.main-wrapper-ed-nego::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.grid-wrapper-ed-nego {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  padding: 0 4%;
}

.menu-wrapper-ed-nego {
  margin-top: 1.5rem;
  background-color: white;
  grid-column: 1/2;
  width: 100%;
}

.content-wrapper-ed-nego {
  background-color: white;
  grid-column: 2/3;
  max-width: 100%;
}
.ed_nego_popover_p {
  font-weight: 500;
  color: #3b3b3b;
  cursor: pointer;
  text-decoration: underline;
}
.ed_nego_popover {
  font-size: 0.8rem;
  color: white;
  background: #3b3b3b;
  width: 300px;
  margin: 0;
  padding: 10px;
}

.content-ed-nego-logo-img {
  height: 4.3rem;
  width: 4.3rem;
  border-radius: 99px;
  margin: 0 10px 0 0;
  -webkit-box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: #ececec solid 1px;
  background-color: #b5b5b5;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}
.content-ed-nego-logo-subir {
  height: 4.3rem;
  width: 4.3rem;
  border-radius: 99px;
  margin: 0 10px 0 0;
  -webkit-box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 14px -8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: #b5b5b5 solid 1px;
  background-color: #b5b5b5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-ed-nego-logo-icon {
  border-radius: 99px;
  position: absolute;
  left: 3px;
  font-size: 1.4rem;
  padding: 4px;
  background: #3b3b3b;
  color: white;
  cursor: pointer;
}

.steps-wrapper-ed-nego {
  width: 100%;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 1rem;
  border: solid 1px rgba(192, 192, 192, 0.1);
  box-shadow: 0px 0px 1px 1px rgba(85, 84, 84, 0.2);
}

.steps-wrapper-ed-nego::-webkit-scrollbar {
  height: 0;
}

.step-mobile-wrapper-ed-nego {
  padding: 2rem 2rem 1rem;
}

.title-ed-nego {
  color: #3b3b3b;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
}
.title-name-ed-nego {
  font-weight: 500;
  font-size: 1.6rem;
}

.ul-ed-nego {
  list-style-type: circle;
}

.li-ed-nego {
  margin-bottom: 12px;
  cursor: pointer;
}
.back-button-ed-neg {
  background-color: #3b3b3b;
  color: white;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 0 1rem -1rem;
  width: 130px;
  font-size: 0.9rem;
  padding: 8px 12px;
}
.back-button-ed-neg:hover {
  background-color: rgba(59, 59, 59, 0.9);
}

.url-txt-instruc-ed-neg {
  font-size: 1rem;
  color: #3b3b3b;
  font-weight: 300;
  text-align: left;
}

@media screen and (max-width: 960px) {
  .back-button-ed-neg {
    font-size: 0.8rem;
    padding: 8px 12px;
    margin: 0 0 1rem 1.8rem;
  }
  .main-wrapper-ed-nego {
    align-items: flex-start;
  }
  .grid-wrapper-ed-nego {
    display: none;
  }
  .title-ed-nego {
    font-size: 1.1rem;
    margin-top: 20px;
  }
  .title-name-ed-nego {
    font-size: 1.3rem;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .content-ed-nego-logo-subir:hover {
    border: rgba(181, 181, 181, 0.7) solid 1px;
    background-color: rgba(181, 181, 181, 0.7);
  }
  .content-ed-nego-logo-img:hover {
    opacity: 0.6;
  }
}

