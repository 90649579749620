.cardPackages-wrp {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: #3b3b3b;
  /* border: solid red 1px; */
  gap: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.cardPackages-discount {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #e43a36 0%, #ff5f5f 100%);
  color: #ffffff;
  padding: 5px;
  border-radius: 0 0 0 8px;
  font-weight: 500;
  font-size: 0.9rem;
  z-index: 2;
}
.cardPackages-discount p {
  margin: 0;
  padding: 0;
}

.cardPackages-img-wrp {
  height: 220px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
}
.cardPackages-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cardPackages-aliado-package-description {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardPackages-package-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.cardPackages-package-publisedBy {
  font-size: 14px;
  margin: 0px 0px 3px 0px;
  color: #5d5d5d;
  font-weight: 300;
}

.cardPackages-aliado-name {
  font-size: 14px;
  min-height: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  color: #36b2ea;
}
.cardPackages-aliado-name:hover {
  text-decoration: underline;
}

.cardPackages-package-entity-category {
  font-size: 14px;
  margin: 0px 5px 10px 0px;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: #5d5d5d;
}

.cardPackages-price {
  margin: 0px 5px 5px 0px;
  font-weight: 300;
  font-size: 14px;
  color: #5d5d5d;
}

@media screen and (max-width: 550px) {
  .cardPackages-wrp {
    flex-direction: column;
    gap: 20px;
  }
  .cardPackages-img-wrp {
    height: 200px;
    width: 100%;
    border-radius: 15px;
  }

  .cardPackages-aliado-package-description {
    width: 100%;
  }
  .cardPackages-price {
    font-size: 13px;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .cardPackages-wrp:hover .cardPackages-img {
    transform: scale(1.2);
    transition: 6s;
  }
}

