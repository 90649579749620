/* Sección 1*/
.afil-img-tit-flx {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -2rem !important;
}
/* FIN - Sección 1*/
/* Sección 2*/
.afil-txt-flx {
  width: 100%;
  display: flex;
  justify-content: center;
}
.afil-img-txt2 {
  font-size: 0.9rem;
  padding: 0 7%;
  text-align: center;
}
/* FIN - Sección 2*/
/* Sección 3*/
.afil-img-txt3 {
  border-radius: 12px;
  width: 80%;
  padding: 1rem 1.5rem;
  text-align: justify;
  font-size: 0.8rem;
  color: #353535;
}
.afil-img-alert {
  border-radius: 12px;
  width: 80%;
  padding: 1rem 1.5rem;
  font-size: 0.8rem;
  color: #353535;
}
/* FIN - Sección 3*/
/* Sección 4*/
.afil-btn-upload {
  border-radius: 15px;
  width: 50% !important;
  color: white;
  padding: 0.9rem 1.5rem;
  text-align: justify;
  /*font-weight: 500;*/
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: linear-gradient(to left, rgb(140, 80, 255) 50%, rgba(140, 80, 255, 0.9) 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;
}
.afil-btn-upload:hover {
  background-color: #ffbd15;
  background-position: left;
}
/* FIN - Sección 4*/
/* Sección 5*/
.afil-img-preview-wrap {
  margin-top: 2rem !important;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
  padding: 0 5%;
}
.afil-img-preview {
  width: 90px;
  margin: 1rem;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.32);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.32);
  cursor: pointer;
}
.afil-img-preview.main {
  border: solid #8c50ff 3px;
}
.afil-img-preview-delete {
  border-radius: 50%;
  padding: 0.4rem 0.4rem;
  color: white;
  background-color: #3b3b3b;
  font-weight: 600;
  position: absolute;
  top: 2px;
  left: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
}
.afil-img-preview-delete:hover {
  opacity: 1;
}
/* FIN - Sección 5*/
/* Sección 6*/
.afil-btns-imgs-flx {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}
.afil-btn-finalizar {
  border-radius: 12px;
  width: 80%;
  color: #3b3b3b;
  padding: 1rem 1.5rem;
  text-align: justify;
  /*font-weight: 500;*/
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-out;
  border: #3b3b3b solid 2px;
  margin-bottom: 1rem;
}
.afil-btn-finalizar:hover {
  border: #4e4e4e solid 2px;
  color: #4e4e4e;
}
.afil-btn-upload-more {
  margin-bottom: 0;
  border-radius: 12px;
  width: 80%;
  color: white;
  padding: 1rem 1.5rem;
  /*font-weight: 500;*/
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-out;
  border: #3b3b3b solid 2px;
  background-color: #3b3b3b;
}
.afil-btn-upload-more:hover {
  border: #4e4e4e solid 2px;
  background-color: #4e4e4e;
}

.afil-btn-upload-more-inactive {
  font-size: 0.9rem;
  width: 40%;
  margin-bottom: 0;
  border-radius: 12px;
  color: white;
  padding: 1rem 1.5rem;
  /*font-weight: 500;*/
  /* font-size: 0.8rem; */
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-out;
  border: #3b3b3b solid 2px;
  background-color: #3b3b3b;
  opacity: 0.7;
  font-size: 0.9rem;
  cursor: default;
}
/* FIN - Sección 6*/

/*----cambio a desktop*/
@media screen and (min-width: 960px) {
  /* Sección 1*/
  .afil-img-tit-flx {
    margin-top: 0 !important;
  }
  /* FIN - Sección 1*/
  .afil-img-txt2 {
    font-size: 1rem;
  }
  /* FIN - Sección 2*/
  /* Sección 3*/
  .afil-img-txt3 {
    width: 40%;
    font-size: 1rem;
  }
  /* FIN - Sección 3*/
  /* Sección 4*/
  .afil-btn-upload {
    width: 80%;
    text-align: justify;
    font-size: 1.2rem;
    justify-content: center;
  }
  /* FIN - Sección 4*/
  /* Sección 6*/
  .afil-btns-imgs-flx {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .afil-btn-upload-more {
    font-size: 0.9rem;
    width: 40%;
  }
  .afil-btn-finalizar {
    font-size: 1.2rem;
    width: 40%;
    margin-bottom: 0;
  }
  /* FIN - Sección 6*/
}
