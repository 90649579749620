.admin-rank-div1{
    margin: 1rem 0 2rem 0;
}
.admin-rank-div1 p{
    color: #3b3b3b;
    font-size: 1rem;
}
.admin-rank-table{
    margin-bottom: 2rem;
}
.admin-rank-table,
.admin-rank-table th,
.admin-rank-table td{
    border: solid #3b3b3b 1px;
}

.admin-rank-table td{
    padding: 3px 10px;
    cursor: pointer;
}

.admin-rank-table tr:nth-child(even){
    background: rgba(52, 92, 192, 0.09);
}
.admin-rank-table tbody tr:hover{
    background: rgb(65, 65, 65);
    color: white;
}