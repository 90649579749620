.packages-wrapper {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  border-radius: 15px;
  cursor: pointer;
  -webkit-box-shadow: 0 19px 7px -14px rgba(81, 81, 81, 0.26);
  box-shadow: 0 19px 7px -14px rgba(81, 81, 81, 0.26);
}

.packages-img-bg {
  position: absolute;
  width: 120%;
  min-height: 200px;
  filter: saturate(120%);
  cursor: pointer;
  z-index: 4;
}
.packages-img-bg:hover {
  transform: scale(1.2);
  transition: 4s;
}
.package-div-content {
  background-color: white;
  border-radius: 15px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 100px;
  width: 200px;
  background: linear-gradient(282.63deg, rgba(255, 255, 255, 0.44) 24.04%, #ffffff 79.72%);
  backdrop-filter: blur(12px);
  z-index: 5;
  padding: 10px;
}
.package-div-content:hover + .packages-img-bg {
  transform: scale(1.2);
  transition: 4s;
}
.package-div-content-title {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.package-div-content-price-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 0;
  max-height: 45px;
  height: 45px;
  overflow: hidden;
}
.package-div-content-price {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
  width: 50%;
}
.package-div-content-button {
  font-size: 0.75rem;
  background-color: white;
  padding: 7px 7px;
  border-radius: 5px;
  font-weight: 400;
  width: 45%;
  margin: 5px 0 0 5%;
  /*-webkit-box-shadow: inset 0 0 6px 1px rgba(71,71,71,0.07);*/
  /*box-shadow: inset 0 0 6px 1px rgba(71,71,71,0.07);*/
}
.package-div-content-button:hover {
  background-color: #0d3b66;
  color: white;
  /*-webkit-box-shadow: inset 0 0 6px 1px rgba(71,71,71,0.3);*/
  /*box-shadow: inset 0 0 6px 1px rgba(71,71,71,0.3);*/
}

.package-div-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.package-div-status {
  margin: 0;
  font-size: 0.75rem;
}
