.paso-welcome-botones-wrp {
  display: flex;
  align-items: center;
  gap: 30px;
}
.paso-welcome-botones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: solid #efefef 3px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.paso-welcome-botones.active {
  border: solid #8c50ff 3px;
}
.paso-welcome-botones.active:hover {
  border: solid #8c50ff 3px;
}
.paso-welcome-botones p {
  font-size: 1rem;
  margin: 0;
}
.paso-welcome-images-flags-div {
  height: 30px;
  width: 50px;
  overflow: hidden;
  border-radius: 10px;
}

.paso-welcome-images-flags-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.paso-welcome-botones:hover {
  border: solid #b9b9b9 3px;
}

