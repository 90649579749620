.bs-sec-wrp {
  width: 90%;
  margin: 100px auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.bs-sec-wrp p {
  margin: 0;
  font-size: 40px;
}

.bs-sec-words-wrp {
  overflow: hidden;
  height: 57px;
}
.bs-sec-words-wrp p {
  color: var(--planodi-purple);
  font-weight: 500;
  font-size: 40px;
  animation: slide-up 25s infinite;
  animation-delay: 2s;
  font-style: italic;
  white-space: nowrap;
}

.bs-sec-img-div {
  width: 60%;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bs-sec-img-div-rounded {
  background: white;
  width: 33%;
  height: 150%;
  top: -4%;
  left: 0;
  position: absolute;
  border-radius: 100%;
  transform: translateX(-50%);
  -webkit-box-shadow: 5px 0px 20px -5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 5px 0px 20px -5px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 0px 20px -5px rgba(0, 0, 0, 0.6);
}
.bs-sec-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.bs-sec-buscador-wrp {
  border: solid black 1px;
}

@keyframes slide-up {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(-100%);
  }
  20% {
    transform: translateY(-200%);
  }
  30% {
    transform: translateY(-300%);
  }
  40% {
    transform: translateY(-400%);
  }
  50% {
    transform: translateY(-500%);
  }
  60% {
    transform: translateY(-600%);
  }
  70% {
    transform: translateY(-700%);
  }
  80% {
    transform: translateY(-800%);
  }
  90% {
    transform: translateY(-900%);
  }
  100% {
    transform: translateY(-1000%);
  }
}

@media screen and (max-width: 1181px) {
  .bs-sec-wrp p {
    font-size: 30px;
  }
  .bs-sec-words-wrp {
    height: 50px;
  }
  .bs-sec-words-wrp p {
    font-size: 30px;
  }
}

@media screen and (max-width: 960px) {
  .bs-sec-wrp p {
    font-size: 25px;
  }
  .bs-sec-words-wrp {
    height: 37px;
  }
  .bs-sec-words-wrp p {
    font-size: 25px;
  }
  .bs-sec-img-div {
    width: 50%;
  }
}

@media screen and (max-width: 735px) {
  .bs-sec-wrp p {
    font-size: 20px;
  }
  .bs-sec-words-wrp {
    height: 30px;
  }
  .bs-sec-words-wrp p {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .bs-sec-wrp {
    flex-direction: column;
  }
  .bs-sec-wrp p {
    font-size: 24px;
  }
  .bs-sec-words-wrp {
    height: 37px;
  }
  .bs-sec-words-wrp p {
    font-size: 24px;
  }
}

