.sitemap-main-wrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sitemap-section-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 0px 10px 0px;
  gap: 15px;
  width: 100%;
  text-align: center;
}

.sitemap-section-wrp p {
  margin: 0 20px;
  padding: 10px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-weight: 500;
}

.sitemap-section-title-active {
  border-bottom: 4px solid var(--planodi-purple);
}
.sitemap-section-title-inactive {
  border-bottom: 4px solid transparent;
}

.sitemap-option-wrp {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 70px 0px 30px 0px;
}

.sitemap-option-title-wrp p {
  font-size: 25px;
  font-weight: 500;
}

.sitemap-option-flex-wrp {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.sitemap-option {
  width: 25%;
  color: black;
  margin-bottom: 1%;
}
.sitemap-option-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.sitemap-option-link:hover {
  color: var(--planodi-purple);
  transition: 0.3s;
}

/* Hover Effects */
.sitemap-section-title-inactive:hover {
  border-bottom: 4px solid var(--planodi-purple);
  transition: 0.2s;
}

/* Celular */
@media screen and (max-width: 1215px) {
  .sitemap-option-wrp {
    padding: 5% 10%;
  }
}

@media screen and (max-width: 1000px) {
  .sitemap-option {
    text-align: left;
    width: 33%;
    color: black;
    margin-bottom: 3%;
    padding: 0px 10px 15px 10px;
  }
  .sitemap-option-title-wrp {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }
  .sitemap-option-title-wrp p {
    font-size: 22px;
  }
  .sitemap-option-wrp {
    padding: 2% 6%;
  }
  .sitemap-option-link {
    text-decoration: none;
  }
}

@media screen and (max-width: 430px) {
  .sitemap-section-wrp {
    padding: 4px 0px 10px 0px;
  }

  .sitemap-option-title-wrp {
    display: flex;
    justify-content: center;
  }
  .sitemap-option-title-wrp p {
    font-size: 18px;
  }
  .sitemap-option-wrp {
    padding: 5% 10% 0% 10%;
  }
  .sitemap-option {
    text-align: left;
    width: 50%;
    color: black;
    margin-bottom: 10%;
    padding: 0px 10px;
  }
}

