.buscador-tabs-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0 0 1rem 0;
}
.buscador-tabs-p {
  margin: 0;
  color: #8d8d8d;
  border-bottom: solid transparent 1px;
  cursor: pointer;
}
.buscador-tabs-p.active {
  margin: 0;
  color: var(--planodi-gray) !important;
  border-bottom: solid var(--planodi-gray) 1px;
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .buscador-tabs-p:hover {
    color: #8d8d8d;
    border-bottom: solid var(--planodi-gray) 1px;
  }
}

