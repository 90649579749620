.landing-aliados-wrp-all {
  overflow: scroll;
}
.landing-aliados-wrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-width: 320px;
}
.landing-aliados-navbar {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 90px;
  background: transparent;
  transition: all 1s;
  min-width: 320px;
}
.landing-aliados-navbar.active {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  height: 70px;
}
.landing-aliados-navbar:after {
  content: '\200b';
}
.landing-aliados-logo {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 100;
  width: 150px;
  cursor: pointer;
  transition: all 1s;
}
.landing-aliados-logo.active {
  top: 16px;
  width: 120px;
}
.landing-aliados-boton {
  position: absolute;
  top: 11px;
  right: 30px;
  --x: 50%;
  --y: 50%;

  appearance: none;
  padding: 1em 2em;
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 100px;
  border: 4px solid transparent;
  background: linear-gradient(#150556, #150556) padding-box,
    radial-gradient(farthest-corner at var(--x) var(--y), #00c9a7, #845ec2) border-box;
  transition: all 1s;
}
.landing-aliados-boton.active {
  top: 11px;
  padding: 0.5em 1em;
  border: 2px solid transparent;
}
.landing-aliados-texto {
  position: absolute;
  z-index: 100;
  /*width: 600px;*/
  width: 50%;
  top: 45%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  /*border: solid green 1px;*/
  padding: 0 0 0 30px;
}
.landing-aliados-h1 {
  font-size: 3rem;
  /*border: solid red 1px;*/
  margin: 0 0 1rem 0;
}
.landing-aliados-h2 {
  font-size: 1.2rem;
  /*border: solid red 1px;*/
  margin: 0;
  font-weight: 300;
  text-align: justify;
}
.landing-aliados-img-fondo {
  background: url('../../Assets/img/landing_aliados/brindis.jpg') fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 99vh;
}
.landing-aliados-img-morado {
  background: url('../../Assets/img/landing_aliados/fondo_morado.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}
.landing-aliados-pantallas {
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  /*width: 700px;*/
  width: 50%;
  /*border: solid black 1px;*/
  z-index: 2;
}
.landing-aliados-circulo-b {
  position: absolute;
  right: -13%;
  transform: translateY(-50%);
  width: 350px;
  z-index: 1;
  opacity: 0.05;
  animation: landingAliadosCirc1 infinite 12s ease-in-out;
}
.landing-aliados-circulo-b2 {
  position: absolute;
  left: -10%;
  transform: translateY(-50%);
  width: 250px;
  z-index: 1;
  opacity: 0.05;
  animation: landingAliadosCirc2 infinite 12s ease-in-out;
}
.landing-aliados-dots {
  position: absolute;
  right: 0%;
  top: 60%;
  transform: translateY(-50%);
  width: 350px;
  z-index: 1;
  opacity: 0.1;
}

.landing-aliados-como-f-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  min-width: 320px;
}
.landing-aliados-como-f-title {
  font-size: 2.1rem;
  color: #3b3b3b;
  font-weight: 600;
  margin: 3rem 0 0.3rem 0;
}
.landing-aliados-como-f-subtitle {
  font-size: 0.9rem;
  color: #3b3b3b;
  text-align: center;
  margin: 0 10% 1rem 10%;
}
.landing-aliados-como-f-title-line {
  width: 150px;
  height: 5px;
  background: #051937;
  margin: 0 0 4rem 0;
  border-radius: 99px;
}
.landing-aliados-como-f-cards-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.landing-aliados-como-f-cards {
  height: 200px;
  width: 25%;
  border-radius: 20px;
  padding: 1rem;
  background: rgb(21, 5, 86);
  background: linear-gradient(132deg, rgb(21, 5, 86) 55%, rgba(92, 16, 148, 1) 78%);
  position: relative;
  overflow: hidden;
}
.landing-aliados-como-f-emoji1 {
  position: absolute;
  bottom: -2rem;
  right: -2rem;
  font-size: 7rem;
  transform: rotate(20deg);
}
.landing-aliados-como-f-cards1 {
  -webkit-box-shadow: 11px 11px 11px 2px rgba(0, 0, 0, 0.28);
  box-shadow: 11px 11px 11px 2px rgba(0, 0, 0, 0.28);
}
.landing-aliados-como-f-emoji2 {
  position: absolute;
  bottom: -2rem;
  right: -1rem;
  font-size: 7rem;
  transform: rotate(-20deg);
}
.landing-aliados-como-f-cards2 {
  -webkit-box-shadow: 1px 23px 11px -9px rgba(0, 0, 0, 0.28);
  box-shadow: 1px 23px 11px -9px rgba(0, 0, 0, 0.28);
}
.landing-aliados-como-f-emoji3 {
  position: absolute;
  bottom: -1.2rem;
  right: -0.3rem;
  font-size: 5rem;
  transform: rotate(12deg);
}
.landing-aliados-como-f-cards3 {
  -webkit-box-shadow: -11px 11px 11px 2px rgba(0, 0, 0, 0.28);
  box-shadow: -11px 11px 11px 2px rgba(0, 0, 0, 0.28);
}
.landing-aliados-como-f-cards p {
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  margin: 1rem;
}

.landing-aliados-faq-box {
  width: 80%;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.landing-aliados-faq-pre {
  color: #3b3b3b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.landing-aliados-faq-pre p {
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
}
.landing-aliados-faq-pre-icon {
  margin-right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 1s;
}
.landing-aliados-faq-pre-icon.open {
  transform: rotate(90deg);
}
.landing-aliados-faq-res {
  background: #f1f5fd;
  color: #3b3b3b;
  transition: all 1s;
  display: block;
}
.landing-aliados-faq-res.close {
  display: none;
}
.landing-aliados-faq-res p {
  padding: 20px 10px 20px 30px;
  margin: 0;
}

.landing-aliados-pasos-wrp {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.landing-aliados-pasos-box {
  width: 50%;
  position: relative;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4rem;
  color: #3b3b3b;
}
.landing-aliados-pasos-box-content {
  padding: 2rem 10% 2rem 10%;
}
.landing-aliados-pasos-box-title {
  font-size: 2rem;
  font-weight: 500;
  margin: 0 0 2rem 0;
}
.landing-aliados-pasos-box-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  z-index: 2;
}
.landing-aliados-pasos-box-circulo {
  position: absolute;
  top: 50%;
  left: -30%;
  transform: translateY(-50%);
  width: 500px;
  z-index: 1;
  animation: landingAliadosCirc3 infinite 16s ease-in-out;
}
.landing-aliados-pasos-box-dots1 {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-50%);
  width: 200px;
  z-index: 1;
}
.landing-aliados-pasos-box-dots2 {
  position: absolute;
  top: 50%;
  right: 10%;
  width: 180px;
  z-index: 1;
}
.landing-aliados-footer {
  height: 250px;
  background: #3b3b3b;
  color: white;
  position: relative;
}
.landing-aliados-footer-block {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 0 0 20px 20px;
  padding-bottom: 20px;
}
.landing-aliados-footer-block:after {
  content: '\200b';
}
.landing-aliados-footer-p {
  width: 300px;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 2rem;
}
.landing-aliados-footer-p2 {
  font-size: 0.6rem;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.landing-aliados-planes-box {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
  padding-bottom: 2rem;
  color: #3b3b3b;
}
.landing-aliados-planes-card {
  border-radius: 20px;
  width: 80%;
  -webkit-box-shadow: 0px 0px 19px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 19px -5px rgba(0, 0, 0, 0.1);
}
.landing-aliados-plan-plus-selection {
  background: url('../../Assets/img/Imagenes_Planes/Plan_3.webp');
  background-repeat: no-repeat;
  background-size: auto 229%;
  background-position: 105% 29%;
  padding: 1rem;
  border-bottom: solid #eeeeee 1px;
}
.landing-aliados-plan-free-selection {
  background: url('../../Assets/img/Imagenes_Planes/Plan_1.webp');
  background-repeat: no-repeat;
  background-size: auto 200%;
  background-position: 105% 45%;
  padding: 1rem;
  border-bottom: solid #eeeeee 1px;
}
.landing-aliados-plan-premium-selection {
  background: url('../../Assets/img/Imagenes_Planes/Plan_2.webp');
  background-repeat: no-repeat;
  background-size: auto 160%;
  background-position: 100% 40%;
  padding: 1rem;
  border-bottom: solid #eeeeee 1px;
}
.landing-aliados-plan-title {
  font-size: 1.8rem;
}
.landing-aliados-plan-info-wrp {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem 1rem 1rem;
}
.landing-aliados-plan-info-content {
  width: 80%;
}
.landing-aliados-plan-info-content p {
  text-align: justify;
  font-size: 0.9rem;
}

/* Celular */
@media screen and (max-width: 960px) {
  .landing-aliados-texto {
    width: 80%;
    margin: 0 10%;
    top: 30%;
    left: 0;
    transform: translateY(-50%);
    padding: 0;
  }
  .landing-aliados-pantallas {
    width: 400px;
    top: 42%;
    left: 50%;
    transform: translateY(0%) translateX(-50%);
  }
  .landing-aliados-h1 {
    text-align: center;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }
  .landing-aliados-h2 {
    text-align: center;
    font-size: 1rem;
    margin: 0;
  }
  .landing-aliados-circulo-b {
    right: -19%;
    width: 300px;
  }

  .landing-aliados-pasos-wrp {
    flex-direction: column-reverse;
  }
  .landing-aliados-pasos-box {
    width: 100%;
    padding-top: 3rem;
    height: 500px;
  }
  .landing-aliados-pasos-box-title {
    font-size: 1.5rem;
    margin: 0 0 2rem 0;
  }
  .landing-aliados-pasos-box-content {
    padding: 0 10%;
    text-align: center;
  }

  .landing-aliados-pasos-box-img {
    width: 120%;
  }
  .landing-aliados-pasos-box-circulo {
    width: 400px;
  }
  .landing-aliados-pasos-box-dots2 {
    top: 60%;
    right: 6%;
    width: 130px;
  }
  .landing-aliados-como-f-title-line {
    margin: 0 0 3rem 0;
  }

  .landing-aliados-como-f-cards-flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .landing-aliados-como-f-cards {
    height: 120px;
    width: 90%;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .landing-aliados-como-f-cards p {
    font-size: 1.2rem;
    margin: 1rem 15% 1rem 1rem;
  }
  .landing-aliados-como-f-title {
    font-size: 1.9rem;
    margin: 2rem 0 0.3rem 0;
  }
  .landing-aliados-como-f-cards1,
  .landing-aliados-como-f-cards2,
  .landing-aliados-como-f-cards3 {
    -webkit-box-shadow: 0px 20px 17px -18px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 20px 17px -18px rgba(0, 0, 0, 0.95);
  }
  .landing-aliados-planes-box {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
}

/* Celular */
@media screen and (max-width: 560px) {
  .landing-aliados-como-f-subtitle {
    margin: 0 5% 1rem 5%;
  }
  .landing-aliados-pantallas {
    width: 400px;
    top: 40%;
    left: 50%;
    transform: translateY(0%) translateX(-50%);
  }
  .landing-aliados-texto {
    width: 94%;
    margin: 0 3%;
    top: 30%;
    left: 0;
    transform: translateY(-50%);
  }
  .landing-aliados-h1 {
    font-size: 1.4rem;
  }
  .landing-aliados-h2 {
    font-size: 0.9rem;
  }
  .landing-aliados-circulo-b {
    right: -40%;
    width: 300px;
  }
  .landing-aliados-dots {
    width: 220px;
  }
  .landing-aliados-circulo-b2 {
    left: -30%;
  }
  .landing-aliados-como-f-cards p {
    font-size: 1rem;
    margin: 1rem 17% 1rem 1rem;
  }
  .landing-aliados-como-f-title {
    font-size: 1.7rem;
  }
  .landing-aliados-faq-box {
    width: 90%;
  }
  .landing-aliados-planes-card {
    width: 94%;
  }
  .landing-aliados-plan-plus-selection {
    background-size: auto 100%;
    background-position: 108% 29%;
  }
  .landing-aliados-plan-free-selection {
    background-size: auto 100%;
    background-position: 105% 45%;
  }
  .landing-aliados-boton {
    top: 20px;
    right: 9px;
    appearance: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
  .landing-aliados-boton.active {
    top: 15px;
    right: 6px;
    appearance: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
  .landing-aliados-logo {
    left: 6px;
  }
  .landing-aliados-pasos-box-dots1 {
    left: 60%;
  }
  .landing-aliados-pasos-box-dots2 {
    top: 90%;
    right: 6%;
    width: 130px;
  }
}

@keyframes landingAliadosCirc1 {
  0% {
    top: 35%;
  }
  50% {
    top: 45%;
  }
  100% {
    top: 35%;
  }
}
@keyframes landingAliadosCirc2 {
  0% {
    top: 80%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 80%;
  }
}
@keyframes landingAliadosCirc3 {
  0% {
    top: 60%;
  }
  50% {
    top: 40%;
  }
  100% {
    top: 60%;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .landing-aliados-boton:hover {
    background: linear-gradient(#1a0962, #1a0962) padding-box,
      radial-gradient(farthest-corner at var(--x) var(--y), #00c9a7, #845ec2) border-box;
  }
}

