.footer-background-solid {
  width: 100%;
  margin-top: 5rem;
  background-color: rgb(246, 246, 246);
  border-top: rgb(190, 190, 190) solid 1px;
  color: #353535;
  min-width: 320px !important;
}

.footer-titulo {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.footer-titulo-right {
  text-align: right;
}
.footer-link {
  display: inline;
  font-weight: 400;
  font-size: 0.9rem;
  padding-left: 0.7rem;
  text-decoration: none;
  margin-bottom: 1rem;
  color: #353535;
}
.footer-link-right {
  width: 100%;
  text-align: right;
  padding-right: 0.7rem;
}
.footer-link:hover {
  text-decoration: none;
  color: #3b3b3b;
}
.footer-logo-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.footer-logo {
  width: 70px;
  opacity: 1;
}
.footer-categories-a {
  font-size: 0.8rem;
  margin: 0 1rem 0 0;
}
.footer-categories-a:visited {
  color: #3b3b3b;
}
.footer-redes-s-wrp-icon {
  margin: 0 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .footer-categories-a:hover {
    text-decoration: none;
  }
  .footer-redes-s-wrp-icon:hover {
    opacity: 0.9;
  }
}

