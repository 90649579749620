.manage-sub-wrp {
  display: flex;
  gap: 20px;
}
.manage-sub-title {
  font-weight: 500;
}
.manage-sub-p {
  margin: 0;
}
.sel-plan-mejora {
  margin: 20px 0 20px 0;
  font-size: 1.1rem;
}
.sel-plan-contenido {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.manage-sub-current-plan {
  margin: 0 auto 10px auto;
  max-width: fit-content;
  padding: 5px 16px;
  border-radius: 99px;
  color: white;
  background-color: #8c50ff;
  background-image: linear-gradient(to right, #6b8dd6, #8e37d7);
}
.manage-sub-reocmendado {
  margin: 0 auto 20px auto;
  max-width: fit-content;
  padding: 5px 16px;
  border-radius: 99px;
  color: #3b3b3b;
  border: solid 1px #51d364;
  font-size: 0.9rem;
}
.manage-sub-mejorar-plan {
  width: 180px;
  background-color: #51d364;
  color: white;
  border-radius: 99px;
  padding: 5px 20px;
  margin: 20px 0 0 auto;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.manage-sub-mejorar-plan:hover {
  background-color: #4be262;
  transition: 0.5s;
}
.manage-sub-sel-plan-card {
  border-radius: 10px;
  /* width: 33.3333%; */
  width: 50%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: solid transparent 3px;
  -webkit-box-shadow: 0 0 14px -10px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 14px -10px rgba(0, 0, 0, 0.6);
}
/*mobile*/
@media (max-width: 960px) {
  .manage-sub-wrp {
    gap: 20px;
    flex-direction: column;
  }
  .manage-sub-sel-plan-card {
    width: 100%;
    max-width: 100%;
  }
}

