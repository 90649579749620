.wrapper-conf-aliado {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	padding-bottom: 80px;
}
.titulo-conf-aliado {
	font-size: 2rem;
	font-weight: 600;
	color: #222222;
	text-align: center;
}
.texto-conf-aliado {
	width: 50%;
	margin: 2rem 0;
	text-align: justify;
}

.img-personaje-conf {
	width: 30rem;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 40%);
	z-index: -1;
}
.paso-conf-table-stripe {
	border: 1px solid #e2e2e2;
	width: 100%;
	display: flex;
	width: 50%;
	margin: 2rem 0;
	border-radius: 7px;
}
.paso-conf-table-stripe div {
	display: flex;
	flex-direction: column;
}
.paso-conf-table-stripe-h {
	font-size: 1rem;
	margin: 0;
	padding: 15px 15px 15px 15px;
	font-weight: 500;
}
.paso-conf-table-stripe-d {
	font-size: 1rem;
	margin: 0;
	padding: 15px 15px 15px 15px;
	border-top: 1px solid #e2e2e2;
}
.paso-conf-table-stripe-factura {
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 1px solid #e2e2e2;
	flex: 1;
	padding: 10px;
}

/* Celular */
@media screen and (max-width: 960px) {
	.titulo-conf-aliado {
		font-size: 1.4rem;
		padding: 0 5%;
	}
	.texto-conf-aliado {
		width: 90%;
		font-size: 0.9rem;
		padding: 0 5%;
	}
	.paso-conf-table-stripe {
		width: 90%;
	}
}

@media screen and (max-width: 622px) {
	.img-personaje-conf {
		width: 90vw;
	}
	.paso-conf-table-stripe {
		flex-direction: column;
		padding-top: 10px;
	}
	.paso-conf-table-stripe div {
		width: 100%;
	}

	.paso-conf-table-stripe-h {
		font-size: 1rem;
		margin: 0;
		padding: 5px 15px 0 15px;
		font-weight: 500;
	}
	.paso-conf-table-stripe-d {
		font-size: 1rem;
		margin: 0;
		padding: 5px 15px 5px 15px;
		border-top: none;
	}

	.paso-conf-table-stripe-factura {
		padding: 20px 10px;
		border-top: 1px solid #e2e2e2;
		width: 100%;
		margin-top: 10px;
		border-left: none;
	}
}
