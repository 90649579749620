.sel-fil-wrp {
  color: var(--planodi-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 40px;
  border-radius: 99px;
  padding: 0 17px;
  cursor: pointer;
  max-width: 200px;
  position: relative;
}
.sel-fil-wrp.full-width {
  max-width: 100%;
  width: 100%;
}
.sel-fil-wrp.with-border {
  border: solid #dbdada 1px;
}
.sel-fil-wrp.dark {
  color: white;
  background: var(--planodi-gray);
}
.sel-fil-wrp.active {
  border: solid var(--planodi-gray) 1px;
}
.sel-fil-wrp.dark.active {
  border: none;
}
.sel-fil-wrp.sharp-edges {
  border-radius: 9px;
}
.sel-fil-wrp.filters-active {
  border: solid #3b3b3b 1px;
}

.sel-fil-notification {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 99%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #3b3b3b;
}
.sel-fil-notification p {
  margin: 0;
  font-size: 14px;
}
.sel-fil-p {
  margin: 0;
  font-size: 15px;
}

.sel-fil-options-wrp {
  position: absolute;
  top: 3.3rem;
  width: 300px;
  background-color: white;
  z-index: 11111998 !important;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  font-family: sans-serif;
  padding: 0;

  box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.6);
}
.sel-fil-options-wrp.width-2 {
  width: 350px;
}
.sel-fil-options-wrp.width-3 {
  width: 400px;
}
.sel-fil-options-wrp.derecha {
  right: 0;
}
.sel-fil-options-wrp.izquierda {
  left: 0;
}

.sel-fil-options-div {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
  /*background-color: rgba(188, 221, 250, 0.09);*/
  background-color: white;
  height: 200px;
  font-size: 0.9rem;
  gap: 4px;
  padding: 15px 15px 0 0;
}
.sel-fil-options-div::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}
.sel-fil-options-div::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: #7c7c7c;
}
.sel-fil-options-div-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sel-fil-options-div-item p {
  margin: 0 0 0 10px;
  flex: 1;
}
.sel-fil-options-div-item-single-select-p {
  margin: 0 !important;
  padding: 5px 3px 5px 17px;
}
.sel-fil-options-div-item-single-select-p.active {
  background-color: var(--planodi-gray);
  color: white;
}

.sel-fil-options-actions-wrp {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid #f5f5f5 2px;
}
.sel-fil-options-borrar {
  font-size: 16px;
  text-decoration: underline;
  color: #3b3b3b;
  padding: 5px 10px;
  border-radius: 10px;
}
.sel-fil-options-borrar:hover {
  background-color: rgb(244, 244, 244);
}
.sel-fil-options-borrar:disabled {
  cursor: auto;
  opacity: 0.6;
  pointer-events: none;
}

.sel-fil-options-guardar {
  font-size: 14px;
  background-color: #3b3b3b;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}
.sel-fil-options-guardar:hover {
  background-color: #2c2c2c;
}
.sel-fil-options-guardar:disabled {
  background-color: #696969;
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}

.modal-filtros-open {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
}
.modal-filtros-open-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70%;
  background: white;
  border-radius: 20px 20px 0 0;
  z-index: 10000001;
  display: flex;
  flex-direction: column;
}
.modal-filtros-open-content-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 15px 30px;
  border-bottom: #dbdada solid 1px;
}
.modal-filtros-open-content-header p {
  margin: 0;
}
.modal-filtros-open-content-ops {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
  background-color: white;
  font-size: 0.9rem;
  gap: 11px;
  padding: 15px 30px 0 30px;
}
.sel-filtros-p-item {
  font-size: 1rem;
}

body:has(.modal-filtros-open) {
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  .sel-fil-options-borrar {
    font-size: 18px;
    padding: 8px 16px;
  }

  .sel-fil-options-guardar {
    font-size: 17px;
    padding: 8px 16px;
  }

  .sel-filtros-p-item {
    font-size: 1rem;
  }
}

/*hover effects*/
@media (hover: hover) and (pointer: fine) {
  .sel-fil-wrp:hover {
    border: solid #3b3b3b 1px;
  }
  .sel-fil-options-div-item:hover {
    background-color: #ebebeb;
  }
  .sel-fil-wrp.dark:hover {
    border: none;
  }
}

